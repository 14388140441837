.tracker-wrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 60px);
    
    .tracker-filter{
        flex: 0 0 210px;
        max-width: 210px;
        padding: 15px 20px 15px 0;
    }
    .tracker-map{
        position: relative;
        flex-basis: 100%;
        max-width: 100%;
        .mapboxgl-map{
            height:100%;
        }
    }
    .filter-title{
        width: 100%;
        position: relative;
        // padding: 0 0 15px 0;
        font-size: pxToRem(16px);
        font-family:$roboto-medium;
        letter-spacing: 0.12px;
        text-align: left;
        background-color: transparent;
        border:none;
        color: $grey-color;
        a
        &:focus{
            box-shadow: none;
        }
        &.btn.active,
        &.btn.show,
        &.btn:first-child:active,
        &:not(.btn-check)+&.btn:active {
            background-color: transparent;
            border:none;
            color: $grey-color;
        }
        &::after { margin-left: 15px; }
    }
    .show .btn-primary.dropdown-toggle{
        background-color: transparent;
        color: $grey-color;
        &:focus{
            box-shadow: none;
        }
    }
    .show .filter-title::after{
        transform: rotate(180deg);
        transition: transform 300ms ease;
        color: $light-blue-color;
    }
    
    li{
        font-family: $roboto-regular;
        padding: 8px 10px;
        text-transform: capitalize;
        cursor: pointer;
        &:hover{
            background-color: $purple-color3;
            color: $light-blue-color;
        }
    }
    .dropdown-menu.show{
        top: -6px!important;
        width: 100%;
        padding: 0;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        z-index: 8;
        ul{
            padding: 8px 0;
        }
        li{
            font-size: pxToRem(14px);
            color: $dark-grey-color;
            &:hover{
                background-color: $grey-color14;
            }
            &.active{
                background-color: $purple-color3;
                color: $light-blue-color;
            }
        }
    }
    
    .tracker-search{
        margin-top: 5px;
        margin-bottom: 15px;
        position: relative;
        .search-input{
            display: flex;
            align-items: center;
            width: 100%;
            background-color: $disabled-color;
            border-radius: 25px;
            padding: 5px 15px;  
        }
        input{
            width: 100%;
            font-size: pxToRem(14px);
            font-family: $roboto-light;
            background-color: transparent;
            color:$label-color;
            border: none;
            &::before{
                color: $grey-color;
            }
            &:focus-visible{  
                outline: none;
            }
        }
        .service-icon{
            @include icomoon("Search", 12px);
            padding-right: 8px;
            &::before{
                color: $grey-color;
            }
        }
    }
    .tracker-result{
        overflow-y: auto;
        height: calc(100% - 60px);
        ul{
            padding: 0;
            li{
                padding: 10px;
                margin-right: 2px;
                font-size: pxToRem(12px);
                &.active{
                    background-color: $purple-color3;
                    color: $light-blue-color;
                }
            }
        }
    }
    .map-refresh-btn{
        position: absolute;
        display: flex;
        align-items: center;
        min-width:80px;
        height: 30px;
        top: 10px;
        right: 55px;
        z-index: 9;
        font-size: pxToRem(11px);
        font-family: $roboto-medium;
        color: $grey-color;
        background-color: $white-color;
        border:2px solid $grey-color6;
        border-radius: 5px;
        @include icomoon("refresh", 14px);
        &::before{
            color: $dark-grey-color;
            padding-right: 10px;
        }
        &.refreshing::before{
            transform: rotate(90deg);
            transition: transform 300ms ease;
            margin-top: 10px;
            padding-right: 11px;
        }
    }

    .timezone-nav{
        position: absolute;
        align-items: center;
        height: 30px;
        // top: 142px;
        bottom: 156px;
        right: 8px;
        z-index: 9;
        input[type='checkbox'] {
            display: none;
        }
        label {
            display: block;
            min-height: 32px;
            padding: 8px 10px; 
            line-height: normal; 
            font-family: $roboto-regular; 
            text-align: center;
            cursor: pointer;
            text-transform: capitalize;
            background-color: $blue-color1;
            color:  $blue-color1;
            border: 1px solid $blue-color1;
            &.all, &.pt, &.mt, &.ct, {
                border-bottom: 0;
            }
            &.all {
                background-color: $grey-color2;                
            }
            &.pt {
                background-color: #ECE7AE;
            }
            &.mt {
                background-color: #E7CAD6;
            }
            &.ct {
                background-color: #C9D7E1;
            }
            &.et {
                background-color: #C5F0BC;
            }
        }
        // input[type='checkbox']+label:hover {
        //     background-color: rgba(11, 32, 50,.92);
        //     // color: $white-color;
        // }
        input[type='checkbox']:checked+label:before {
            content: '✔';
            margin-right: 5px; 
            color:  $blue-color1;
        }
        // input[type='checkbox']:checked+label,
        // input[type='checkbox']:checked+label:before { color: $white-color; }
    }
    
    .mapbox-overlay{
        position: absolute;
        width: 100%;
        max-width: 205px;
        height: 100%;
        padding: 15px;
        background-color: $white-color;
        box-shadow: 3px 0 3px rgba(0, 0, 0, 0.10) inset;
        z-index: 9;
        overflow-y: auto;
        .close-icon{
            cursor: pointer;
        }
        .overlay-type{
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            .service-icon{
                position: relative;
                width: 45px;
                height: 45px;
                background: $disabled-color;
                border-radius: 50%;
                &.icon::before{
                    position: absolute;
                    color: $grey-color15;
                    top: 12px;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
                &.lab{
                    @include icomoon("flask", 23px);
                }
                &.hospital{
                    @include icomoon("Hospital", 23px);
                }
                &.facility{
                    @include icomoon("care-facility", 23px);
                }
            }
        }
        .facilty-header{
           color: $grey-color;
           p,h2{
            font-family: $roboto-bold;
           }
            .facility-name{
                font-size: pxToRem(14px);
                padding-bottom: 2px; 
            }
            .facility-id{
                font-size: pxToRem(12px);
                line-height: initial;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .facilty-details{
            color: $black-color3;
            p,h2{
                font-family: $roboto-regular;
                line-height: 15px;
            }
            .detail-title{
                font-size: pxToRem(10px);
                color: $black-color3;
            }
            .detail-subtile{
                font-size: pxToRem(14px);
                &.email{
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .emergency-contact{
            .facilty-details{
                p,h2{
                    font-family: $roboto-regular;
                }
            }
            .emergency-contact-box{
                padding: 15px 10px;
                border: 2px solid $light-blue-color3;
            }
            h2{
                font-size: pxToRem(14px);
                font-family: $roboto-bold;
                color:$light-blue-color;
            }
        }
    }
    &.user-guide-wrapper {
        height: calc(100% - 90px);
        margin: 0 auto;
        justify-content: center;
        padding: 40px 10px;
        margin-top: 12px;
        background-color: #f8f9fa;
        iframe {
            width: 80%;
            padding: 20px;
            background-color: #fff;
        }
    }
}
.icon-tracker-wh{
    width: 52px;
    height:76px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
}
.marker-pin {
    // width: 30px;
    // height: 30px;
    // border-radius: 50% 50% 50% 0;
    // background: #c30b82;
    // position: absolute;
    // transform: rotate(-45deg);
    // left: 50%;
    // top: 50%;
    // margin: -15px 0 0 -15px;
    // z-index: 99;
    background: none;
    border: none;
}

// .map-phelb-pin {
//     @include icomoon("pheb-pin", 96px);
// } 
// .map-hos-pin {
//     @include icomoon("hospital-pin", 96px);
// }
// .map-facility-pin {
//     @include icomoon("facility-pin", 96px);
// } 
// .map-lab-pin {
//     @include icomoon("lab-pin", 96px);
// }

.marker {
    background-color: $disabled-color;
    border: 5px solid $grey-color;
    color: $white-color;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    border-radius: 50%;
}

// Global Search styles
.gs-list-wrapper {
    padding: 20px 15px 20px 35px;
    .gs-search-link {
        font-family: $roboto-regular;
        color: $light-blue-color;
        text-decoration: underline;
    }
}