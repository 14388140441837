.report-modal {
    overflow: hidden;
    &.sc-copycal-modal 
    .modal-dialog { 
        max-width: 469px; 
        height: unset; 
        margin-top: 90px;
    }
    .modal-content { min-height: 540px; }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
    }
    .reports-icon-wrapper {
        width: 100px;
        height: 100px;
        padding-left: 10px;
        background: rgba(127,121,224,.2);
        border-radius: 50%;
    }
    input {
        color: #121212;
        width: 100%;
        border: 1px solid rgba(18, 18, 18, 0.2);
        &:hover { cursor: pointer; }
    }
    .date-icon { 
        top: 68%; 
        &::before { color: #767676; }
    }
    .reports-list-tablewrapper {
        width: 100%;
        max-height: 200px;
        padding-right: 5px;
        margin: 30px 0 20px;
        overflow-y: auto;
        table { border: 1px solid #D4D4D4; }
        td { 
            padding: 15px;
            font-size: pxToRem(12px);
        }
        td:nth-child(2) { 
            vertical-align: middle;
            text-align: right; 
        }
        @include breakpoint(min1920) {
            max-height: 350px;
        }
    }
    .backto-dashboard { 
        margin-top: 15px;
        display: block;
        text-decoration: underline;
        color: #3127A5; 
    }
}