.stat-order-wrapper {
    position: relative;
    height: calc(100% - 71px);
    width: 100%;
    &.ac-top-wrapper {
        width: auto;
        height: auto;
    }
    
    .stat-order-filter-wrapper {        
        display: flex;
        width: 100%;
        height: 100%;
        .st-filter-expand {
            flex: 0 0 30px;
            max-width: 30px;
            height: 100%;
            margin-right: 3px;
            background-color: $dark-blue-color;
            text-align: center;
            &:hover { cursor: pointer;}
            p {  
                width: auto;
                padding: 0;
                color: $white-color;  
                writing-mode: tb;
            }
        }
        .back-to-top-wrapper {
            position: fixed;
            bottom: 55px;
            right: 30px;
        }
        .back-to-top {
            display: flex;
            height: 36px;
            width: 38px;
            padding-top: 4px;
            transform: rotate(-90deg);
            background-color: $light-blue-color;
            border-radius: 5px; 
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.33);
            border: none;
            .mob-back::before { color: $white-color; }
            &:hover { background-color: $link-blue-color; }
        }
        &.homehealth-filter-wrapper {            
            li.st-filter-selected-info {
                display: block;
                margin-right: 0;
                font-size: pxToRem(12px);
                & + .st-filter-selected-info::before {
                    position: static;
                }
                .st-filter-title {
                    padding: 0 4px;
                    font-size: pxToRem(12px);
                }
            }
            &.filter-expanded {
                // .ad-grid-wrapper {
                //     height: calc(100% - 60px);
                // }
                @include breakpoint(maxmin1366) {
                    .hh-has-more-filter {
                        .st-filter-selected-info {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            margin-right: 16px;
                            .st-filter-title {
                                padding: 0;
                            }
                           
                            & + .st-filter-selected-info::before {
                                position: absolute;
                                left: -14px;
                                top: 12px;
                            }   
                        }
                    }
                    
                } 
            }   
                 
        }
        &.main-stat-filter-wrapper {
            &.filter-expanded {
                // .st-help-container { top: 72px;}
            }
            // li.value-empty {
            //     &.st-filter-selected-info + .st-filter-selected-info::before { top: 3px; }
            // }   
            &.has-more-filter {
                .ad-grid-wrapper { height: calc(100% - 58px);}
                &.filter-expanded {
                    @include breakpoint(maxmin1366) {
                        .st-filter-selected-info,
                        .st-filter-title { 
                            font-size: 12px;
                        }
                    }
                }
                .st-filter-selected-info {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-right: 16px;
                    // font-size: 12px;
                    .st-filter-title {
                        padding: 0;
                        // font-size: 12px;
                    }
                   
                    & + .st-filter-selected-info::before {
                        position: absolute;
                        left: -14px;
                        top: 12px;
                    }
                }
            }
            &.filter-collapsed {
                @include breakpoint(maxmin1366) {
                   
                   
                    .ad-headerbottom-wrapper {
                        height: calc(100% - 70px);
                    }
                    .filter-expanded {
                        // .e-gridcontent {
                        //     height: calc(100% - 93px);
                        // }
                        
                    }
                    // .e-gridcontent {
                    //     height: calc(100% - 117px);
                    // }
                    .st-left-filter-wrapper { margin-top: 0;}
                    .stat-order-filter-wrapper { padding: 0; }
                    // div.ad-grid-wrapper { height: calc(100% - 33px);}
                    .stat-order-wrapper{
                        height: calc(100% -34px);
                    }
                    .icon-arrow-down-outlined{
                        transform: rotate(0);
                    }
                    .st-help-container { top: 70px; }
                }
            }
        }
       
        &.filter-expanded {
            width: calc(100% - 216px);
            .filter-expand {
                transform: rotate(180deg);
            }
            // .ad-grid-wrapper {
            //     height: calc(100% - 40px);
            // }
            .has-more-filter {
                .st-filter-selected-info {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-right: 16px;
                    .st-filter-title {
                        padding: 0;
                    }
                    & + .st-filter-selected-info::before {
                        position: absolute;
                        left: -14px;
                        top: 12px;
                    }
                }
            }
           
        }
        .st-filter-expand-icon {
            position: absolute;
            top: 8px;
        }
        .st-filters-text {
            font-size: pxToRem(14px);
            font-family: $roboto-regular;
            line-height: 20px;
            letter-spacing: 0.5px;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            color: #fff;
        }
        .filter-expand {
            &:hover { cursor: pointer; }
            &:before { color: #fff; }
        }
    }
    .mr-10{
        margin-right:10px;
    }
    .stat-filter-wrapper {
        height: calc(100% - 34px);
        .stat-input-group {
            padding-bottom: 16px;
            border-bottom: 1px solid #CFCFCF;
        }
        .st-filter-accordions {
            height: calc(100% - 160px);
            margin-top: 12px;
            overflow-y: auto;            
        }
        .info-icon {
            &::before {
                color: #6D66DC;
            }
        }
    }
    .st-left-filter-wrapper {
        padding: 5px 12px;
        flex: 0 0 216px;
        max-width: 216px;
        border: 1px solid $light-grey-color;  
        .st-reset-all-btn  {
            font-size: pxToRem(12px) !important;
            font-family: $roboto-regular !important;
            .reset-all {
                padding-top: 2px;
                &::before {
                    font-size: pxToRem(14px);
                }
            }           
        }           
        .st-filterby-text {
            padding-left: 5px;
            font-family: $roboto-bold;
            color: $label-color;
        }
        
        .st-left-top-wrapper {
            border-bottom: 1px solid $light-grey-color;
        }
    }

    .stat-order-toggle {
        // display: flex;
        // justify-content: center;
        position: absolute;

        .ad-toggle-switch {
            position: relative;
        }
    }

    .accordion-item {
        background: $white-color2;
        border: none;
        border-radius: unset;
    }
    .filter-accordian {
        display: flex;
        align-items: center;
        // padding: 10px;
    }
    p {
        //width: 118px;
        width: 6%;
        padding: 0 20px 0 10px;
        font-size: pxToRem(12px);
        font-family: $roboto-regular;
        line-height: 18px;
        letter-spacing: 0.216px;
        // background: $white-color2 ;
        color: $dark-blue-color ;

        // &::after {
        //     content: '\e91a';
        //     position: relative;
        //     width: auto;
        //     height: auto;
        //     margin-left: 15px;
        //     font-size: pxToRem(5px);
        //     font-family: 'icomoon';
        //     color: $grey-color ;
        //     background-image: none;
        //     border: none;
        //     color: $grey-color ;
        // }

        // &:focus,
        // &:not(.collapsed) {
        //     padding-bottom: 8px;
        //     background-color: transparent;
        //     border: none;
        //     box-shadow: none;
        // }
    }

    .accordion-body {
        padding: 0px 10px 10px;
    }

    .stat-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .stat-fields {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 95%;
        @include breakpoint(max1024) {
            flex-wrap: wrap;
        }
        .sch-statlisting-select__menu .sch-statlisting-select__option  input { min-height: 22px;}
    }

    .stat-clear-all {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 7%;
        padding-right: 15px;
        cursor: pointer;

        span {
            font-size: pxToRem(12px);
            font-family: $roboto-medium;
            line-height: 20px;
            color: $red-color3;
        }

        .filters {
            @include icomoon("close", 14px);

            &::before {
                position: relative;
                top: 1px;
                padding-right: 5px;
                color: $red-color3;
            }
        }
    }

    .sch-statlisting-dropdown,
    .react-datepicker-wrapper {
        width: 170px;
        height: 40px;
        padding: 0 6px;

        @media screen and (min-width: 1920px) {
            width: 230px;
        }
    }

    .sch-statlisting-select__control,
    .stat-input-group input {
        min-height: 40px;
        // min-height: 35px;
        background: transparent;
        border-color: rgba(18, 18, 18, 0.2);
        color: $label-color !important;
        cursor: pointer;
        &:focus {
            box-shadow: 0 0 0 4px $blue-color19;
            outline: none;
            border-color: $blue-color7;
        }

        &::after {
            display: none;
        }

        .sch-statlisting-select__multi-value {
            margin: 0;
            background-color: transparent;
        }

        .sch-statlisting-select__indicator-separator {
            display: none;
        }

        .sch-statlisting-select__dropdown-indicator {
            @include icomoon("arrow-down", 6px);
            svg {
                display: none;
            }
        }

        &.sch-statlisting-select__control--menu-is-open {
            .sch-statlisting-select__dropdown-indicator::before {
                padding-left: 8px;
                transform: rotate(180deg);
                transition: transform 300ms ease;
                color: $light-blue-color;
            }
        }

        .sch-statlisting-select__value-container--is-multi {
            input {
                position: absolute;
            }
        }

        .sch-statlisting-select__clear-indicator,
        .sch-statlisting-select__multi-value__remove {
            @include icomoon("close", 11px);

            svg {
                display: none;
            }
        }
    }

    .stat-input-group {
        width: auto;
        @include icomoon("Schedule", 16px);
        .daterange-form-input {
            min-width: 192px;
            
        }
        &::before {
            position: absolute;
            right: 10px;
            top: 12px;
            
        }
    }
    .st-accordian-wrapper {   
        .accordion-collapse {
            margin: 0 4px 0 0;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
        }
        &.disabled { 
            opacity: .4; 
            pointer-events: none;
        }
        .stat-rv-facility-select-dropdown {
            height: auto !important;
            max-height: 160px !important;
            overflow: hidden !important;
            background-color: #fff;
            & > div {
                height: 160px !important;
                overflow-y: auto !important;
            }
            }
        .accordion-button:not(.collapsed) {
            background: transparent;
            box-shadow: none;
        }
        .accordion-item { 
            background: #fff; 
        }
        .accordion-button {
            padding: 8px 2px;
            font-family: $roboto-regular;
            font-size: pxToRem(12px);
            line-height: 20px;           
            letter-spacing: 0.25px;
            color: $label-color;
            background-color: #fff;
            &:focus {
                background: none;
                box-shadow: none;   
            }
            @include icomoon("arrow-down", 5px);
            &.collapsed {
                &::before {
                    display: inline-block;
                    transform: rotate(180deg);
                }
            }
            span { padding-left: 8px;}
            &:after {
                display: none;
            }
        }
        .st-filter-checkbox-wrapper{
            padding-left: 15px;
        } 
        .st-label-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 15px;
            padding: 0;
            .ml-checkmark {
                position: static;
                margin-right: 10px;
                flex: 0 0 16px;
                height: 16px;
                width: 16px;
                border: 2px solid rgba(0, 0, 0, 0.6);
                border-radius: 3px;
            }
            .st-checkbox-label {
                font-size: pxToRem(12px);
                font-family: $roboto-regular;
                color: #2D3038;
                line-height: 1.4;
            }
        }
    }
    .react-datepicker__tab-loop .react-datepicker-popper{
        z-index: 5;
    }
    .react-datepicker-wrapper {
        padding-left: 10px;
        
        .react-datepicker__input-container {
            position: relative;
            @include icomoon("arrow-down", 6px);
            &::before {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:focus-within{
                &::before{
                top: 44%;
                transform: rotate(180deg);
                transition: transform 300ms ease;
                color: $light-blue-color;
                }
            }
            input {
                cursor: pointer;
            }
        }

        input {
            width: 100%;
            height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 30px !important;
            border-width: 1px;
            border-color: rgba(18, 18, 18, .2);
            background: transparent;
            cursor: pointer;

            &:hover,
            &:focus {
                box-shadow: 0 0 0 4px $blue-color19 ;
                outline: none;
                border-color: $blue-color7 ;
            }
        }
    }
    #customGrid-homeHealth .e-gridheader{
        z-index: 3;
    }
    #newOrderGrid-homeHealth .e-gridheader{
        z-index: 3;
    }
    .homehealth-filter .filter-accordian{
        p{
            padding: 0;
        }
        
        .ad-toggle-switch{
            &:first-of-type:after{
                border: 0;
            }
            .ad-switch{
                padding-right: 0;
                line-height: 15px;
            }
            input:checked + .slider{
                box-shadow: 0 0 1px #1E3D71;
                background-color: #1E3D71;
                opacity: 1;
            }
            &.radio-disabled .ad-switch{
                opacity: 1;
            }
        }
        .switch{
            width: 30px;
            height: 12px;
            padding: 0 17px;
            margin: 0 10px;
        }
        @media screen and (min-width: 1920px) {
            p{
                width:80px;
            }
            .stat-filter{
                flex-basis: 100%;
            }
        }
    } 

    .sch-statlisting-select__placeholder,
    .react-datepicker-wrapper input,
    .react-datepicker-wrapper input::placeholder {
        max-width: 100%;
        padding: 2px 8px;
        font-size: pxToRem(14px);
        font-family: $roboto-regular;
        line-height: 20px;
        letter-spacing: 0.252px;
        border-radius: 4px;
        color: $label-color;
    }


    .sch-statlisting-select__indicator-separator,
    .react-datepicker__close-icon {
        display: none;
    }

    .sch-statlisting-select__indicator {
        @include icomoon("arrow-down", 5px);
        
        &::before {
            padding-right: 12px;
        }

        svg {
            display: none;
        }
    }

    .sch-statlisting-select__menu {
        z-index: 9;
        min-width: 165px;

        .sch-statlisting-select__option {
            display: flex;
            // align-items: flex-start;
            align-items: center;
            // height: 35px;

            &:hover {
                background-color: transparent;
            }

            &.sch-statlisting-select__option--is-selected {
                input {
                    background-color: $blue-color2;
                }
            }
            input {
                flex: 0 0 18px;
                height: 18px;
            }
        }

        .sch-statlisting-select__option--is-selected,
        .sch-statlisting-select__option {
            background-color: transparent !important;
        }

        label {
            display: flex;
            padding-left: 15px;
            font-size: pxToRem(14px);
            font-family: $roboto-regular;
            line-height: unset;
            text-transform: capitalize;
            color: $label-color;
            &:hover { cursor: pointer; }
        }
    }

    .e-toolbar {
        // border-top: 0 !important;
        margin-bottom: 8px;
    }

    // .e-toolbar-right {
    //     padding: 0 20px !important;
    // }
    .st-order-right-wrapper {
        position: relative;
        flex: 0 0 calc(100% - 33px);
        width: calc(100% - 33px);
        // height: calc(100% - 135px);
        height: 100%;
        .st-reset-all-btn  {
            font-size: pxToRem(12px) !important;
            font-family: $roboto-regular !important;
            .reset-all {
                padding-top: 2px;
                &::before {
                    font-size: pxToRem(14px);
                }
            }           
        }
        .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item {
            .e-tbar-btn  {
              padding: 3px 15px 5px !important;
              height: auto;
              min-height: auto;
              border-radius: 50px;
              border: 1px solid #6D66DC;
              line-height: 1;
              .e-excelexport {
                display: none;
              }
            }
          }
    }
    .ad-grid-wrapper {
        width: 100%;
        height: calc(100% - 58px);
        .e-gridheader {
            border-top: 1px solid $light-grey-color;
        }
    }

    .e-grid .e-gridheader {  
        position: -webkit-sticky;  
        position: sticky;  
        top: 0px;
        z-index: 1;  
      } 

    .e-grid .e-gridcontent {
        //height: calc(100% - 55px);
        height:auto;
        // .e-content {
        //     height: auto !important;
        // }
        min-height: 330px;
        @include breakpoint(min1920) {
            min-height: 680px;
        }

        .stat-order-status {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 200px;
            min-height: 36px;
            padding: 10px;
            text-align: center;
            border-radius: 5px;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: pxToRem(14px);
                font-family: $roboto-regular;
                text-align: center;
                line-height: 10px;
                color: $label-color;
            }

            .icon {
                &::before {
                    padding-right: 10px;
                    color: $black-color !important;
                }

                &.redispatched,
                &.cancelled,
                &.redispatch {
                    @include icomoon("refresh", 16px);
                }

                &.dropped-off {
                    @include icomoon("drop-off", 16px);
                }

                &.unacknowledged {
                    @include icomoon("hourglass-bottom", 16px);
                }

                &.check-in {
                    @include icomoon("check-in", 16px);
                }

                &.check-out {
                    @include icomoon("check-out", 16px);
                }

                &.acknowledged {
                    @include icomoon("visibility", 18px);
                }

                &.unassigned {
                    @include icomoon("assignment-late", 16px);
                }
                &.partial-completion {
                    @include icomoon("partial-completion", 18px);
                }
                &.unscheduled{
                    @include icomoon("unscheduled", 16px);
                }
                &.scheduled{
                    @include icomoon("scheduled", 18px);
                }
                &.assigned-to-coordinator{
                    @include icomoon("assigned-to-coordinator", 18px);
                }
                &.assigned{
                    @include icomoon("assigned", 18px);
                }
                &.unclaimed{
                    @include icomoon("unclaimed", 16px);
                }
            }
        }

        .e-groupcaption {
            padding: 15px 20px 15px 15px;
            font-size: 12px;
            font-family: 'robotoregular';
            line-height: 19.2px;
            color: $dark-blue-color ;
        }

        .e-icons {
            font-weight: 600;
            color: $black-color
        }

        .e-sortfilter .e-group-intent {
            width: 10px;
        }
    }

    .e-grid .e-gridheader tr th:first-child {
        border-left: 1px solid $grey-color7;
    }

    .sch-statlisting-select__indicator.sch-statlisting-select__clear-indicator {
        display: none !important;
    }

    .sch-statlisting-select__multi-value__remove:hover {
        background: transparent;
        color: $black-color;
    }
    &.open-order-enabled {
        .st-filter-accordions {
            height: calc(100% - 10px);
        }
    }
    
}
/*Bubbles Count Design
.stat-notify-bubble.bg-primary{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    font-size: 10px;
    margin-left: 5px;
    padding-left: 8px;
    background-color: #c6262d !important;
}*/
.stat-order-count{
    font-size: 14px;
    font-weight: 600;
}

.items-count-wrap{
    position: absolute;
    width: auto;
    bottom: 1px;
    right: 8px;
    font-size: pxToRem(14px);
    font-weight: 600;
    background-color: $white-color;
    color: $grey-color4;
    z-index: 9;
}
.e-pagenomsg {
    margin-right: 1px;
}
.e-ccdiv.e-cc-toolbar {
    padding-right: 130px !important;

    @media screen and (min-width: 1920px) {
        padding-right: 10% !important;
    }
}
.e-toolbar-item.info-icon-tooltip {
    min-width: 15px !important;
    .info-icon {
        &::before {
            color: #6D66DC;
        }
    }
    // span:hover {
    //     position: relative;
    //     display: inline-block;
    // }
    // span[title]:hover:after {
    //     content: attr(title);
    //     position: absolute;
    //     background: red;
    //     z-index: 100000;
    //     padding: 40px 8px;
    //     right: 0;
    //     top: -100%;
    // }
}
.e-toolbar-item.client-export-class {
    min-width: 172px !important;
}
.default-clear-btn{
    color:#6c757d;
    @include icomoon("mob-close", 11px);
    position: absolute;
    top: 30px;
    right: 85px;
    cursor: pointer;
    &.error{
        color: #C6262D;
    }
}
.e-toolbar-left {
    right: 16px !important;
    .e-toolbar-item,
    .e-tbar-btn.e-btn { padding: 0 !important; }
    .e-tbar-btn.e-btn {
        text-align: center;
        background: none;
        border: none;
        // border-radius: 5px !important;
        cursor: pointer;
        &:hover,
        &:focus { 
            background: none;
            border: none;
            .e-tbar-btn-text {
                color:$light-blue-color;
            }
        }

        .e-tbar-btn-text {
            padding: 0 !important;
            font-family: $roboto-medium;
            font-weight: normal;
            font-size: pxToRem(12px);
            line-height: 16px;
            letter-spacing: 0.098px;
            color:$light-blue-color;
        }
        .e-excelexport {
            @include icomoon("export", 18px);
        }
    }
}
.create-order-modal,
.ad-adduser-modal {
   

    .sch-schedule-select__menu {
        z-index: 100;
        .sch-schedule-select__option,
        .sch-schedule-select__option--is-focused{
            font-family: $roboto-regular;
            font-size: pxToRem(14px);
            padding: 8px 12px;
            background-color: transparent;
            color:$label-color;
            &:hover,&:focus{
              color:$dark-grey-color;
              background-color: rgba(0,0,0,0.08)!important;
            }
            &:not(:last-of-type){
                margin-top:5px;
            }           
        }
        .sch-schedule-select__option--is-selected {
            background-color: #2684FF !important;
            color:$white-color;
            &.sch-schedule-select__option--is-disabled{
                background-color: transparent !important;
                color: #121212;
                &:hover { 
                    cursor: default;
                    background-color: transparent !important;
                }
            }
        }
    }

    .e-disabled input,
    .e-disabled input::placeholder,
    input:disabled::placeholder {
        color: $label-color !important;
    }
    .sch-schedule-select__indicator-separator,
    .sch-schedule-select__loading-indicator {
        display: none;
    }

    .sch-schedule-select__control{
        &:focus-within {
            box-shadow: 0 0 0 4px $blue-color19;
            outline: none !important;
            border-color: $blue-color7;
        }
    }

    .sch-schedule-select__value-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 40px;

        input:focus {
            box-shadow: none !important;
        }
    }

    .sch-schedule-select__control--is-disabled .sch-schedule-select__input-container {
        visibility: visible;
    }

    .sch-schedule-select__control:focus-within{
        .sch-schedule-select__indicator::before{
        top: 44%;
        transform: rotate(180deg);
        transition: transform 300ms ease;
        color: $light-blue-color;
        }
    }

    .sch-schedule-select__placeholder {
        position: absolute;
        font-size: pxToRem(14px);
    }

    .sch-schedule-select__indicator {
        svg {
            display: none;
        }

        @include icomoon("arrow-down", 5px);

        &::before {
            padding-right: 2px;
            color: $grey-color19 ;
        }
    }

    .sch-schedule-select__indicator.sch-schedule-select__clear-indicator {
        @include icomoon("close", 11px);
        cursor: pointer;
        &:before {
            color: #919191;
        }

        svg {
            display: none;
        }
        &:focus-within::before,
        &::before { 
            padding-top: 2px;
            top: unset !important;
            transform: none !important; 
            color: $grey-color19 !important;
        }
    }

    .error-message {
        position: relative;

        &.missing {
            width: 90%;
            display: block;
            align-items: center;
            padding-bottom: 10px;
            margin-top: -17px;

            p.error-message-text {
                font-size: pxToRem(12px);
                font-style: italic;
                text-transform: capitalize;
                color: $dark-blue-color !important;
            }
        }

        .sch-schedule-select__control {
            border-color: $red-color;
        }

        .e-input-group:not(.e-success):not(.e-warning):not(.e-error) {
            border-color: $red-color;
        }
    }

    .e-input-group-icon.e-search-icon,
    .e-input-group-icon {
        position: absolute !important;
        right: 0;
        top: 5px;
    }
    .e-control-wrapper {
        align-items: center;
        height: 40px;
    }
    .mapboxgl-ctrl-geocoder {
        min-width: auto !important;
        font-size: pxToRem(14px);
    }

    input[type=date],
    input[type=datetime-local] {
        padding-right: 5px;
    }
}
.create-order-modal {   
    input,
    .sch-schedule-select__input {
        min-width: auto !important;
        height: 40px;
        font-family: $roboto-regular;
        font-size: pxToRem(14px);
        padding-left: 10px;
        letter-spacing: 0.18px;
        border: none;
        border-radius: 4px;
    } 
    &.territory-order-modal {
        .sch-schedule-dropdown {
            input,
            .sch-schedule-select__input {
                width: auto !important;
            }
            .sch-schedule-select__value-container { display: grid !important;}
        }
        .aha-modalform-group {
            width: auto !important;
        }
        input,
        .sch-schedule-select__input,
        .sch-schedule-select__control {
            width: 240px !important;
        }
        // .flex-50 {
        //     flex: 0 0 50% !important;
        //     max-width: 50% !important;
        //     margin-right: 10px !important;
        // }
        .error-message .e-input-group {
            border-color: #C6262D !important;
        }
        .modal-droprdown-input { 
            border: 1px solid #ced4da !important; 
        }
    }

    .aha-modalform-group {
        position: relative;
        width: 235px;
        &.aha-timezone-select-wrapper { width: 65px; }
        
        @media screen and (min-width: 768px) and (max-width:1024px) {
            width: 215px;
        }

        .e-input-group {

            input,
            input:hover {
                border: none !important;
            }

            &.e-disabled {
                background-color: $grey-color9 !important;
            }
        }

        &.middle-initial {
            width: auto;
        }

        .order-info-phleb input {
            width: 200px;
        }
        // &.type-courier label { min-height: 34px;}
        .e-clear-icon {
            position: absolute;
            right: 20px;
            top: 13px;
        }
    
    }
    .st-acc-ph-wrapper { 
        max-width: 235px; 
        margin-top: -15px;
    }
    .st-acc-ph-number {
        min-height: 12px !important;
        margin-right: 12px;
        font-family: $roboto-medium;
        font-style: italic;
        line-height: 1.1;
        color: #999;  
        font-size: pxToRem(14px);      
    }
    
    .phlebotomist-activities {
        span {
            font-family: $roboto-regular;
            font-size: pxToRem(14px);
            padding-left: 20px;
            line-height: 19px;
            color: $light-blue-color;
            cursor: pointer;
            @include icomoon("autorenew", 16px);

            &:before {
                color: $light-blue-color;
                padding-right: 10px;
            }
        }
    }

    .view-attachment {
        padding-bottom: 20px;

        p {
            display: flex;
            font-family: $roboto-regular;
            font-size: pxToRem(14px);
            font-style: italic;
            color: $light-blue-color;

            .view-btn {
                cursor: pointer;
            }

            .file-name {
                width: 500px;
                padding-right: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                overflow: hidden;
            }
        }
    }
    .st-notify-client-container,
    .st-hospital-notified { 
        flex: 0 0 100%; 
    }
    .st-notified-wrapper {
        align-self: center;
        max-width: 255px;
        min-height: 88px;
    }
    .notify-client-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 4px 0px 8px 0;
        
        input {
            width: 18px;
            height: 18px;
            margin: 0 10px 0 0;
            &:disabled { cursor: not-allowed;}
            &:hover { cursor: pointer; }
        }
        label {
            padding: 0;
            font-size: 14px;
            color: $dark-grey-color;
            line-height: 1.2;
        }
        &.disabled {
            opacity: .5;
        }
    }
    input.attachment-file {
        position: relative;
        padding-top: 10px;
        padding-right: 30px;
        background-color: $white-color;

        &::-webkit-file-upload-button {
            display: none;
        }

        @include icomoon("logout", 14px);

        &:before {
            position: absolute;
            top: 10px;
            right: 9px;
            display: block;
            transform: rotate(-90deg);
            color: $black-color;
        }
    }

    .cancelOrder-btn {
        display: flex;
        align-items: center;
        width: auto;
        font-family: $roboto-regular;
        font-weight: 700;
        font-size: pxToRem(14px);
        line-height: 1;
        padding-left: 10px;
        margin: 3px 0 0 10px;
        transform: translateY(-50%);
        color: $light-blue-color;
        cursor: pointer;
        @include icomoon("cancel-order", 18px);

        &:before {
            // position: absolute;
            // left: 16px;
            // top: -2px;
            color: $light-blue-color;
            padding-right: 10px;
            font-weight: 700;
        }
        &.ack-order-btn {
            padding-left: 30px;
            @include icomoon("visibility", 20px);
        }
        &.force-ack-order-btn {
            @include icomoon("hourglass-bottom", 20px);
        }
        
    }
    .st-cancel-ack-wrapper {
        position: absolute;
        left: 250px;
        top: 4px;
        min-height: 80px;
        margin-top: 5px;
        align-items: center;
    }
    .testing-info {
        position: relative;
        display: flex;
        align-items: center!important;

        // .aha-modalform-group {
        //     min-height: auto!important;
        // }
        .close-icon {
            top: 35%;
            right: 0;
        }
        .date-icon{
            top: 46%;
        }
    }

    .test-code-btn {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        .primary-btn.primary-btn-outline.outline {
            border: none;
        }
        &.add-accession {
            margin: 0 0 0 8px;
        }
    }

    .instruction-wrapper {
        .aha-modalform-wrapper.instruction-notes {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 28px;

            .aha-modalform-group {
                width: auto;
                flex-basis: 48.5%;
            }

            .instruction-notes-display {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 50px;
                text-align: center;
                background-color: $grey-color1;

                p {
                    font-size: pxToRem(12px);
                    font-family: $roboto-medium;
                    color: $black-color3;
                }
            }

        }

        .order-notes {
            font-family: $roboto-medium;
            font-size: pxToRem(13px);
            color: $grey-color17;
            text-transform: initial;
        }

        textarea {
            padding: 10px;
        }
    }

    .notes-display-wrapper {
        height: 180px;
        overflow: scroll;

        .notes-list {
            position: relative;
            padding-bottom: 20px;
        }

        .title {
            font-family: $roboto-medium;
            font-size: pxToRem(12px);
            line-height: 20px;
            letter-spacing: 0.216px;
            color: $grey-color17;

            span {
                font-family: $roboto-regular;
                padding-left: 10px;
            }
        }

        .description {
            width: 90%;
            font-family: $roboto-regular;
            font-size: pxToRem(12px);
            line-height: 18px;
            letter-spacing: 0.216px;
            color: $grey-color17;
        }

        .close-icon {
            top: 0;
        }
    }

    .postnote-btn {
        display: flex;
        padding-bottom: 28px;
    }

    .close-icon {
        position: absolute;
        right: 3%;
        cursor: pointer;
        @include icomoon("close", 14px);

        &::before {
            color: $grey-color11;
        }
    }
    .acc-date-time-picker {
        min-height: auto;
        .datetime-icon {
            top: 67%;
        }
    }
    .datetime-icon {
        position: absolute;
        top: 46%;
        right: 9px;
        transform: translateY(-50%);
        pointer-events: none;
        cursor: pointer;
        @include icomoon("calendar_timer", 38px);
    }

    .date-icon {
        position: absolute;
        top: 47%;
        right: 9px;
        transform: translateY(-50%);
        pointer-events: none;
        cursor: pointer;
        @include icomoon("Schedule", 17px);

        &::before {
            color: $black-color;
        }
    }

    .datetime-picker-wrapper {
        input {
            &[type=datetime-local] {
                padding-right: 20px;
            }

            &[type=date] {
                padding-right: 5px;
            }

            &~.datetime-icon,
            &~.date-icon {
                background-color: $white-color;
            }
            &~.datetime-icon {
                top: 38%;
                height: 25px;
            }
            &:disabled {

                &~.datetime-icon,
                &~.date-icon {
                    background-color: transparent;
                }
            }
        }
        &.hh-result-final-time {
            .sch-schedule-select__indicators { display: none; }
            .icon-timer::before { font-size: 18px;}
        }
    }
}

.cancel-text-popup {
    color: $red-color3;
}

.btn.popup-seenotes, .btn.popup-seenotes:hover, .btn.popup-seenotes:active {
    font-weight: 500;
    font-size: 14px;
    padding-left: 0;
    text-decoration: underline;
    box-shadow: none;
    border: 0;
    color: $light-blue-color;
    .copy-calendar::before {
        color: $light-blue-color;
    }
}

.view-atachment-modal {
    text-align: center;

    img {
        width: 178px;
        height: 147px;
        margin: 20px 0;
        object-fit: contain;
        line-height: 16px;
        border: 1px solid $grey-color6;
    }

    p {
        font-family: $roboto-regular;
        font-size: pxToRem(12px);
        color: $grey-color6;
    }
}

.drag-column-wrapper {
    // position: relative;
    // padding: 10px 20px;
    // background: $white-color2;
    // border-width: 0 1px 0 1px;
    // border-style: solid;
    // border-color: $light-grey-color;
    position: absolute;
    width: 35%;
    margin: 0 auto;
    top: 9px;
    left: -65px;
    right: 0;
    z-index: 3;
    border: none;
    padding: 0;
    background-color: $white-color2   !important;
    border-width: 1px;
    border-style: solid;
    border-color: $light-grey-color;
    @media only screen and (max-width: 1200px)  {
        display: none;
    }
    // @media only screen and (max-width: 1365px)  {
    //     width: 32%;
    // }
    @media only screen and (max-width: 1600px)  {
        width: 30%;
    }
    .e-groupdroparea::after,
    .e-groupdroparea::before{
        border: none !important;
    }

    .e-groupdroparea {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 34px !important;
        padding: 0 5px !important;
        background-color: $white-color2   !important;
        border-style: unset !important;
        border-width: 0 !important;
        font-size: pxToRem(12px) !important;
        font-family: $roboto-regular  !important;
        color: $grey-color6  !important;
        &:hover {
            background-color: $white-color2 ;
        }

        &::before,
        &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: calc(100% - 40px);
            height: 100%;
        }

        &::before {
            top: 0;
            border-top: 1px solid $light-grey-color;
        }

        &::after {
            bottom: 0;
            border-bottom: 1px solid $light-grey-color;
        }

        &.e-grouped {
            justify-content: flex-start;
            padding: 5px 0;
            background-color: transparent !important;

            .e-groupheadercell {
                margin: 0 6px;
                z-index: 2;

                // &:first-of-type {
                //     margin-left: 0;
                // }

                &>div {
                    display: flex;
                    justify-content: center;
                    align-items: center
                }
            }

            .e-groupsort {
                display: none;
            }

            .e-grouptext {
                font-size: pxToRem(12px);
                font-family: $roboto-regular;
                line-height: 19.2px;
                color: $black-color2;
            }

            .e-icons::before {
                padding-left: 10px;
                font-weight: 600;
                color: $grey-color17;
            }
        }
        &.e-focused { box-shadow: none; }
    }
}
.page-stat-order {
    &.page-care-home {
        .ad-right-wrapper .ad-headerbottom-wrapper {    height: calc(100% - 45px);}
    }
    body::-webkit-scrollbar{
        width:8px;
        height:8px;
    }
    .tab-content {
        height: auto !important;
        min-height: calc(100% - 34px);
    }
    .ad-headerbottom-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .tab-content {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .ad-toggle-switch {
            position: static;
            transform: none;
            &.up-ad-toggle-switch {
                // flex: 1;
                margin: 15px 0;
                .ad-switch {
                  flex: 1;
                  line-height: 1.2;
                }
                // .switch {
                //     margin: 0 12px 0 10px;
                //     flex-basis: 23%;
                // }
            }
            &:first-of-type:after{
                content: '';
                display: block;
                height: 20px;
                padding-left: 2px;
                // border-right: 1px solid $grey-color7;
            }
            &:not(:last-of-type) {
                padding-right: 10px;
            }
        }
        .new-stat-order-wrapper { 
            flex: 0 0 160px;
            max-width: 160px;
        }
        .new-stat-order-container {
            width: 100%;
            min-height: 30px;
        }
        .new-stat-order {
            min-width: 135px;
            margin: 0;
            height: 30px; 
            border-radius: 40px;
            .add-order-new {                
                padding-right: 10px;
                line-height: 1;
            }
        }
        .ad-navtab {
            flex: 0 0 88%;
            max-width: 88%;
            // padding-top: 10px;
            @include breakpoint(max1600) {
                flex: 0 0 86%;
                max-width: 86%;
                overflow: auto;
            }
            @include breakpoint(max1024) {
                flex: 0 0 78%;
                max-width: 78%;
            }
            .nav-item{
                cursor: pointer;
            }
                
        }
        .help-nav-tab {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .adduser-row {
            position: absolute;
            width: 58%;
            top: 0;
            right:0;
            z-index: 3;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    } 
    .stat-order-wrapper{
        .main-stat-filter-wrapper.has-more-filter.filter-expand  {
            .e-gridcontent{
                height: calc(100% - 110px);
            }
        }
        .e-gridcontent{
            height: calc(100% - 165px);
            min-height: auto;
            overflow: auto;
            ::-webkit-scrollbar{
                width:8px;
                height:8px;
            }
        }
    }
    .ad-right-stat-order-content-wrapper{
        height: 100%;
        overflow: hidden;
        &.so-toggle-active {
            padding-bottom: 0;
            .st-help-container { display: none; }
            .ad-headerbottom-wrapper {
                height: calc(100% - 65px);
            }
            .main-stat-filter-wrapper {
                @include breakpoint(maxmin1366) {
                    .e-gridcontent {
                        height: calc(100% - 98px);
                    }
                }
            }
            .e-gridcontent {
                height: calc(100% - 98px);
            }
            .new-stat-order-container {
                min-height: auto;
            }
            
            // .homehealth-filter-wrapper.filter-expanded {
            //     .e-gridcontent {
            //         height: calc(100% - 50px);
            //     }
            // }
            // .e-gridcontent {
            //     height: calc(100% - 82px);
            // }
            .st-left-filter-wrapper { margin-top: 0;}
            .stat-order-filter-wrapper { padding: 0; }
            .ad-grid-wrapper {    height: calc(100% - 40px);}
            .filter-collapsed .ad-grid-wrapper {    height: calc(100% - 58px);}
            .stat-order-wrapper{
                    height: 100%;                
            }
            .icon-arrow-down-outlined{
                transform: rotate(0);
            }
        }
    }
    .header-left{
        display: flex;
        align-items: center;
        .icon-arrow-down-outlined{
            font-family: icomoon;
            font-size: 11px;
            margin-left: 10px;
            transform: rotate(180deg);
            cursor: pointer;
            &::before{
                color:$dark-blue-color;
            }
        }
    }
    .so-hide-btn{
        height: 30px;
        font-size: 1rem;
        border: none;
        border-radius: 25px;
        padding: 0 20px;
        margin-left: 15px;
        color: $white-color;
        background-color: $light-blue-color;
        &:hover{
            background-color: $blue-color;
        }
    }
    .ad-navtab.nav-tabs::before{
        border:0;
    }
}
.ad-right-stat-order-content-wrapper{
    .stat-order-wrapper .st-accordian-wrapper {
        .accordion-body { position: static;}
        div {
            z-index: 9;
        }
    }
    .stat-order-wrapper .filter-accordian.accordion .accordion-item{
        position: relative;
        padding: 10px 0;
    }
    .stat-order-wrapper .accordion-body{
        position: absolute;
        top: 12px;
        right: 0;
        padding: 0;
    }
    .stat-order-wrapper .sch-statlisting-dropdown, 
    .stat-order-wrapper .react-datepicker-wrapper {
        width: 150px;
    }
    #grid-component-statOrder{
        position: relative;
    }
    .drag-column-wrapper .e-groupdroparea{
        min-height: 37px !important;
    }
    .ad-grid-wrapper .e-grid .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper .e-input-group.e-search{
        width:240px;
    }
    .stat-order-wrapper .accordion-header button::after{
        content: ' ';
    }
}
.st-help-container {
    position: absolute;
    top: 76px;
    left: 315px;
    z-index: 3;
    @include breakpoint(maxmin1366) {
        top: 90px !important;
    }
}
.phleb-info-wrap{
    // padding-top: 10px;
}
.phleb-info-wrap p {
    font-size: 12px;
    color: #767676;
    text-overflow: ellipsis;
    word-break: break-all;
}
.phleb-info-wrap p span{
    color: black;
}
.stat-filter-wrapper .sch-statlisting-select__control--is-disabled{
    background-color: #F2F2F2;
    color: #121212;
}
.stat-info-modal {
    .modal-dialog {
        height: auto;
        margin-top: 70px;
    }
    .help-nav-tab {
        flex: 0 0 100%;
        max-width: 100%;
        .nav-link {
            padding: 10px 20px;
            background-color: transparent;
            &.active { font-family: $roboto-medium; }
        }
    }
    .modal-header .btn-close::before { color: $black-color; }
    
    .stat-help-modal {
        &::before { 
            font-size: 40px; 
            color: $black-color;
        }
    }
    .st-help-wrapper {
        align-items: center;
        margin: 20px 0 0 5px;
        padding: 0 0 10px;
        // border-bottom: 1px solid $grey-color20;
    }
    .color-box-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .color-box {
        flex: 0 0 13%;
        margin-right: 10px;
        width: 62px;
        height: 25px;
        border-radius: 2px;
        &.st-box-green { background-color: #6EFA8E; }
        &.st-box-orange { background-color: #FAB96E; }
        &.st-box-red { background-color: #FF8381; }
        &.st-box-yellow { background-color: $yellow-color; }
        &.st-box-grey { background-color: $grey-color20; }
        &.st-box-purple { background-color: #B18FCF; }
    }
    .color-info-text {
        font-size: pxToRem(11px);
        line-height: 15px;
        color: $black-color;
    }
    .st-help-title {
        font-size: pxToRem(14px);
        line-height: 19px;
        color: $black-color;
    }
    .st-help-text { font-size: pxToRem(13px); }
    .st-bordered {
        padding: 10px 10px 0;
        margin-bottom: 10px;
        border: 1px solid #CCCCCC;   
    }
}

.home-health-popup {
    .st-cancel-ack-wrapper{
        top: 3px;
        left: 500px;
    }
    .icon-timer{
        position: absolute;
        top: 30px;
        right: 5px;
        z-index: 99;
        width: 20px;
        &:before{
            font-family: 'icomoon';
        }
    }
    #schedule-start-time,
    #schedule-end-time{
        .sch-schedule-select__indicator{
            opacity:0;
        }
        .sch-schedule-select__option--is-disabled {
            opacity: 0.5;
            
        }
        &.pt-sch-time {
            .sch-schedule-select__indicator{
                opacity:1;
            }
            .sch-schedule-select__dropdown-indicator {
                opacity: 0;
            } 
        }
    }
    
    input:disabled, 
    textarea:disabled{
        opacity: 0.7;
    }
    .partner-kit-comments{
        height:17px;
    }
    .e-disabled,
    .sch-schedule-select__control--is-disabled,
    .aha-modalform-group input:disabled{
        background-color: #F2F2F2!important;
    }
    .sch-schedule-select__control--is-disabled .sch-schedule-select__single-value{
        color: #121212;
        opacity: 0.6;
        font-size: pxToRem(14px);
    }
    .scheduled-date-field-wrap{
        position:relative;
    }
    .scheduled-date-field {
        .date-icon {
            top: 52%;
        }
        &.error-message {
            .date-icon {
                top: 36%;
            }
        }
    } 
    #partner-kit-comments{
        height:auto;
    }
    .react-datepicker-popper{
        z-index: 99;
    }
    #service-type{
        text-transform: capitalize;
    }
    .icon-unclaimed{
        font-size: 14px;
        font-weight: 600;
        margin: -15px 0 0 10px;
        cursor:pointer;
        color: #6D66DC;
        &:before{
            font-family: icomoon;
            font-size: 16px;
            padding-right: 10px;
        }
    }
    .aha-modalform-wrapper {
        .aha-modalform-wrapper{
            width: 100%;
            margin-bottom: 15px;
            &:first-of-type{
                margin-top: 0;
            }
        }
    }
    .aha-modalform-wrapper.patientRDT{
        margin-top: 0;
    }
    .home-health-border{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -7px;
            border-bottom: 1px solid #767676;
        }
    }
    .view-attachment.hh{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
    }
    .care-pop-fetchbtn.primary-btn.primary-btn-outline.outline{
        position: absolute;
        top: 20px;
        right: 0;
        min-width: auto;
        width: 30%;
        margin: 0;
        cursor: pointer;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $light-blue-color;
        color: white;
        &[disabled]{
            border:0;
            background-color: #adabd0ff;
            color:#3b3b48ff;
        }
    }
    .txt-green-msg{
        font-size: 12px;
        text-transform: capitalize;
        color: $green-color;
    }
}
.st-form-group {
    width: auto;
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 200px !important;
    &.aha-st-notes-group {
        flex: 0 0 47.5%;
        max-width: 47.5%;
    }
    textarea {
        padding: 10px;
    }
    &.error-message {
        textarea {
            border-color: $red-color !important;
        }
    }
}

    .daterangepicker {
        .calendar-table {
            .today { color: #FF8381; }
            td, th { font-size: pxToRem(14px);}
            td {
                font-family: $roboto-regular;
                font-weight: normal;
                &.active,
                &.active:hover  { 
                    background-color: $dark-blue-color;
                }
            }
            th {
                font-family: $roboto-medium;
                font-weight: normal;
            }
        }
        .drp-selected {
            font-family: $roboto-regular;
        }
        .drp-buttons {
            .btn {
                &:disabled {
                    background: $light-grey-color;
                    border-color: $light-grey-color;
                    color: $label-color;
                }
            }
            .cancelBtn {
                color: $light-blue-color ;
                border: 1px solid $light-blue-color;
            }
            .applyBtn {
                background-color: $light-blue-color;
                border-color: $light-blue-color;
                &:hover,
                &:focus {
                    background: $blue-color;
                }
            }
        }
    } 
    .st-react-datepicker-wrapper {
        display: flex;
        .react-datepicker__time-container,
        .react-datepicker__month-container { 
            min-height: 300px;
            float: none;
        }
        ul.react-datepicker__time-list li.react-datepicker__time-list-item,
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            font-size: pxToRem(14px);
            font-family: $roboto-regular;
            color: $dark-grey-color;
        }
        ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            height: auto !important;
            padding: 10px 10px !important;
        }
        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            font-family: 'robotomedium';
            font-weight: normal;
            font-size: 16px;
            color: $black-color2;
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected  { 
            background-color: $dark-blue-color;
            color: $white-color !important;
            font-weight: normal;
        }
        .react-datepicker__day--today { color: #FF8381; }
        .react-datepicker__header { background-color: $grey-color18; }
        .react-datepicker__navigation:hover *::before,
        .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
        border-color: $black-color2;
        }
        .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
        color: #ccc !important;
        }
        .react-datepicker__month-select,
        .react-datepicker__year-select,
        .react-datepicker__month-select:focus-visible {
            padding: 5px;
            margin: 2px;
            font-family: $roboto-regular;
            border: none;
            background: transparent;
            color: $black-color4;
        }
    }
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {border-bottom-color: $grey-color18;}
.react-datepicker__close-icon {
    top: 1px;
    right: 52px;
    @include icomoon("close", 11px);
    &:before {
        color: #919191;
    }
    &::after { display: none !important;}
}
.pt-scheduled-date .react-datepicker__close-icon {
    top: 2px;
    right: 32px;
    &:before { font-size: 12px };
} 
.sch-schedule-select__single-value {  font-size: pxToRem(14px);}
.filter-reset-btn {
    flex: 0 0 auto;
    .primary-btn.primary-btn-outline.outline.st-reset-all-btn {
        min-width: auto;
        border: none;
        height: auto;
        padding: 2px 0;
        font-size: pxToRem(14px);
    }
}
.st-filter-selected-info-wrapper {
    margin-left: 5px;
    padding-bottom: 1px;
    .st-filter-selected-info {
        font-size: pxToRem(12px);
        // @include breakpoint(maxmin1366) {
        //     font-size: pxToRem(12px);
        // }
        // padding: 0 4px;
        .st-filter-title {
            padding: 0 4px;
            font-size: pxToRem(12px);
            color: #121212;
            // @include breakpoint(maxmin1366) {
            //     font-size: pxToRem(12px);
            // }
        }
        .st-selected-value {
            padding-right: 4px;
            font-family: $roboto-regular;
            color: $light-blue-color;
        }
        & + .st-filter-selected-info {
            @include icomoon("mob-back", 8px);
            &::before {
                display: inline-block;
                padding: 2px 0 0;
                transform: rotate(180deg);
                color: $light-grey-color;
            }
        }
    }
}
.toggle-title-wrapper {
    display: flex;
    align-items: center;
    .info-icon::before {
        font-size: 14px;
    }
    .toggle-title-text {
        padding-right: 10px;
        line-height: 1;
        font-size: 13px;
    }
}
.ah-divider {
    padding: 0 12px;
    border-right: 2px solid #ccc;
}
.st-notify-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    min-height: auto !important;
    .notify-client-wrapper input {
        width: 18px !important;
        height: 18px !important;
    }
    label { 
        margin-right: 10px !important;
        font-size: 13px !important;
    }
}

.clear-internal-grid-button {
    margin: 0 0 0 12px;
    .primary-btn.primary-btn-outline.outline {
        color: $red-color;
        font-size: 0.75rem !important;
    }
}
//auto refresh styles
.st-filter-selected-wrapper {   
    padding: 8px; 
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #C6C6C6;
    .auto-refresh-wrapper {
        flex: 0 0 auto;
        padding-left: 12px;
        z-index: 9;
        .auto-refresh-disabled {
            .slider {
                background-color: $red-color3;
            }
            .ad-switch {
                color: $red-color3 !important;
            }
        }
        .ad-switch {
            flex: 0 0 auto;
            line-height: 1.2 !important;
        }
        .last-refresh,
        .countdown {
            display: flex;
            width: 100%;
            min-width: 92px;
            flex-direction: column;
            align-items: center;
            span:first-child {
                color: #121212;
            }
            span:nth-child(2) {
                color: #777;
            }
            &.disabled {
                opacity: .4;
            }
        }
        .last-refresh {
            align-items: flex-start;
        }
        .countdown {
            height: 100%;
            padding: 5px;
            margin: 0 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
        .st-refresh-button {
            min-width: 100px;
            height: 100%;
            padding: 10px;
            border-radius: 4px;
        }
    }
}
.filter-expanded {
    .drag-column-wrapper {                
        @media only screen and (max-width: 1500px) {
            display: none;
        }
    }
}

//auto complete address field - custom
.choose-time-wrapper.dropoff-time-wrapper {
    .autocomplete-address-wrapper {
        width: max-content;
        input {
            width: 235px;
        }
    }
}
.autocomplete-address-wrapper {
    .cus-search-icon {
        position: absolute;
        top: 12px;
        left: 10px;
    }
    input {
        padding: 6px 30px !important;
        height: 40px;
        border-color: rgba(18, 18, 18, 0.2);
        font-size: 0.875rem;
        color: rgba(18, 18, 18, 0.87) !important;
        border-radius: 4px;
        font-family: "robotoregular", sans-serif;
        box-shadow: none;
        text-overflow: ellipsis;
        &:focus {
            box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.25);
            outline: none;
        }
    }
    .autocomplete-clear-icon {
        position: absolute;
        top: 11px;
        right: 6px;
        border: none;
        background-color: transparent;
        &:hover { cursor: pointer; }
        &:focus,
        &:focus-visible {
            outline: none;
            background-color: transparent;
        }
    }
    .cus-autocomplete-options {
        position: absolute;
        z-index: 11;
        max-height: 200px;
        overflow: auto;
        width: 100%;
        li {
            padding: 6px 12px;
            font-family: $roboto-regular;
            font-size: pxToRem(14px);
            line-height: 16px;
            letter-spacing: 0.33px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.08);
                cursor: pointer;
            }
        }

    }
}

.cus-dropdown-container input {
    &:focus,
    &:focus-visible {
        box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
        outline: none;
    }
}
.react-datepicker-wrapper { width: 100%; }