@import 'variables';
@import 'mixins';
@import 'common';
@import 'login';
@import 'mainnav';
@import 'adminpage';
@import 'schedule';
@import 'tracker';
@import 'pluginchat';
@import 'chat';
@import 'statorder';
@import 'reports';

//Base styles
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: $white-color;
  font-size: $font-size16;
  font-family: $roboto-regular;
  line-height: 1.2;
  text-align: left;
  color: $black-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
.App,
#root { height: 100%; }
#root {
    display: flex;
    flex-direction: column;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  line-height: 1.2;
}
a {
	font-family: $roboto-medium;
    font-size: pxToRem(11px);
    line-height: 1.2;
    color: $link-blue-color;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $link-blue-color;
        text-decoration: underline;
    }
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.primary-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 280px;
    height: 40px;
    margin: 0 auto;
    background-color: $light-blue-color;
    color: $white-color;
    font-size: pxToRem(14px);
    font-family: $roboto-bold;
    text-transform: capitalize;
    border: none;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    &:hover,
    &:focus {
        background: $blue-color;
    }
    &:disabled {
        background: $light-grey-color;
        color: $label-color;
    }
    &.primary-btn-outline{
        min-width: 200px;
        margin: unset;
        &.outline{
            color: $light-blue-color ;
            border: 1px solid $light-blue-color;
            background: none;
            &:not(:last-child){
                margin-right: 15px;
            }
        }
        &.server-export-button {
            min-width: auto;
            border-radius: 50px;
            padding: 4px 12px !important;
            height: auto;
            margin-right: 8px !important;
            font-family: $roboto-regular;
            font-size: 13px;
        }
    }
    &.ad-progress-btn {
        background-color: $light-blue-color;
        &:hover,
        &:focus { background: $blue-color; }
        border: none;
        & .e-progress {background-color: #E2E0F8; }
        &.e-btn {
            .e-spinner-inner svg {
                height: 20px;
                width: 20px;
                transform-origin: 10px 10px 10px;
                fill: $light-blue-color; stroke: $light-blue-color;
                & .e-path-circle { stroke: $light-blue-color; }
            }
            &:focus { box-shadow: 0 0 0 4px $blue-color3; }
        }
        &.e-progress-active { background-color: $blue-color5 !important;}
        &:disabled {
            background: $light-grey-color;
            color: $label-color;
        }
    } 
}
.nav-expand-collapse-btn {
    display: flex;
    min-height: 80px;
    padding: 10px 15px 0;
    background-color: transparent;
    border: none;
    .close-icon::before { 
        font-size: 14px;
        color: $white-color;
    }
}
input.form-control {
    display: block;
    min-width: 280px;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid $input-border-color; 
    font-family: $roboto-light;
    font-size: pxToRem(16px);
    color: $dark-grey-color !important;
    &.invalid { border: 1px solid $gradient-red-color; }
    &:disabled,
    &.disabled {
        background-color: $disabled-color;
    }
   &:disabled:-webkit-autofill,
   &:disabled:-webkit-autofill:hover, 
   &:disabled:-webkit-autofill:focus, 
   &:disabled:-webkit-autofill:active  {
       box-shadow: 0 0 0 30px $disabled-color inset;
    -webkit-box-shadow: 0 0 0 30px $disabled-color inset !important;
    }
}
.form-check {
    margin-right: 30px;
    .form-check-label {
        font-size: pxToRem(14px);
        line-height: 28px;
        letter-spacing: .18px;
        font-family: $roboto-regular;
    }
}
.form-check-input[type=radio],
.sc-checkbox-wrapper .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 2px solid $grey-color;
    &:checked {
        background-color: $dark-blue-color;
        border-color: $dark-blue-color;
    }
    &:focus { box-shadow: 0 0 0 0.125rem $blue-color3; }
}
label {
    font-size: pxToRem(12px);
    line-height: 32px;
    text-align: left;
    font-family: $roboto-medium;
    color: $label-color;
}
//Heading and paragraph classes
.headline1 {
    font-family: $roboto-light;
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -.15px;
}
.headline2 {
    font-family: $roboto-light;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -.8px;
}
.headline3 {
    font-family: $roboto-regular;
    font-size: 48px;
    line-height: 56px;
}
.headline4 {
    font-family: $roboto-regular;
    font-size: 34px;
    line-height: 36px;
}
.headline5 {
    font-family: $roboto-regular;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: .75px;
}
.headline6 {
    font-family: $roboto-medium;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .75px;
    text-transform: capitalize;
}
.headline7 {
    font-family: $roboto-medium;
    font-size: pxToRem(18px);
    line-height: 20px;
    letter-spacing: 0.324px;
}
.subtitle1 { letter-spacing: .9px; }
p,
.subtitle1,
.body1 {
    font-family: $roboto-regular;
    font-size: pxToRem(16px);
    line-height: 24px;
}
.subtitle2 {
    font-family: $roboto-medium;
    font-size: pxToRem(14px);
    line-height: 24px;
    letter-spacing: .7px;
}
.body2 {
    font-family: $roboto-regular;
    font-size: pxToRem(14px);
    line-height: 20px;
    letter-spacing: .18px;
}
.caption {
    font-family: $roboto-regular;
    font-size: pxToRem(12px);
    line-height: 16px;
    letter-spacing: .33px;
}
.overline {
    font-family: $roboto-medium;
    font-size: 10px;
    line-height: pxToRem(16px);
    letter-spacing: .150px;
}
//Color classes
.white-color {color: $white-color; }
.red-color { color: $red-color; }
.grey-color { color: $grey-color4; }
.black-color { color: $black-color !important; }
.light-black-color { color: $black-color2; }
//Font size classes
.font-size-8 { font-size: pxToRem(8px); }
.font-size-9 { font-size: pxToRem(9px); }
.font-size-10 { font-size: pxToRem(10px); }
.font-size-11 { font-size: pxToRem(11px); }
.font-size-12 { font-size: pxToRem(12px) !important; }
.font-size-13 { font-size: pxToRem(13px); }
.font-size-14 { font-size: pxToRem(14px) !important; }
.font-size-16 { font-size: pxToRem(16px); }
.font-size-20 { font-size: pxToRem(20px); }

//font family classes
.roboto-light { font-family: 'robotolight', sans-serif; }
.roboto-regular { font-family: 'robotoregular', sans-serif !important; }
.roboto-medium { font-family: 'robotomedium', sans-serif; }
.roboto-bold { font-family: 'robotobold', sans-serif !important; }

//Padding and margins classes
.mab-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mt-10 { margin-top: 10px; }
.mb-12 { margin-bottom: 12px; }
.mb-15 { margin-bottom: 15px; }
.mt-15 { margin-top: 15px; }
.mb-20 { margin-bottom: 20px !important; }
.mt-20 { margin-top: 20px; }
.mr-20 { margin-right: 20px; }
.mb-25 { margin-bottom: 25px; }
.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-20 { padding-left: 20px; }
.pr-10 { padding-right: 10px; }
.pab-5 { padding-bottom: 5px; }
.pal-8 { padding-left: 8px; }
.w-245 { width: 245px; }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
    box-shadow: 0 0 0 30px $white-color inset;
}
