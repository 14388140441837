.main-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-image: url(../../assets/images/AHA-watermark.svg),
    linear-gradient(120deg, $dark-blue-color 40%, $gradient-red-color 100%);
    background-repeat: no-repeat;
    background-position: -60px calc(100% + 60px), center;
    background-size: 45%, cover;
    @include breakpoint(max1600) {
        background-position: -65px calc(100% + 40px), center;
        background-size: 46%, cover;
    }
    @include breakpoint(max1024) {
        background-position: -65px calc(100% + 70px), center;
        background-size: 62%, cover;
    }
}
header {
    padding: 0 55px 0 30px;
    min-height: 172px; 
    @include breakpoint(max1600) {
        min-height: 50px;
        padding: 12px 30px 0;
    }
    @include breakpoint(max1024) {
        min-height: 105px;
    }
    img {
        width: 275px;
        height: auto;
        @include breakpoint(max1600) {
            width: 175px;
        }
        @include breakpoint(max1024) {
            width: 150px;
        }
    }
}
footer { 
    padding: 25px 55px; 
    @include breakpoint(max1600) {
        padding: 15px 55px;
    }
}
.signup-wrapper {
    max-width: 800px;
    min-height: 605px;
    margin: auto;
    border-radius: 8px;
    background: $white-color;
    box-shadow: 0px 6px 16px rgba(30, 61, 113, 0.25);
    .signup-left-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        padding: 40px 15px 8px;
        .login-title {
            font-size: pxToRem(24px);
            line-height: 24px;
            letter-spacing: 0.009375em;
            color: $dark-blue-color;
            @include breakpoint(max1600) {
                font-size: pxToRem(20px);
            }
        }
        .login-info {
            max-width: 280px; 
            margin-left: auto;
            margin-right: auto;
            font-size: pxToRem(14px);
            line-height: 20px;
            color: $light-black-color;
            text-align: center;
            &.manually-enter-wrapper { min-height: 30px; }
            @include breakpoint(max1600) {
                max-width: 345px;
                br { display: none; }
            }
            a { 
                font-size: pxToRem(14px);
                line-height: 20px;
                &:hover { cursor: pointer; }
            }
            .aha-secret-key {
                max-width: 159px;
                padding: 3px 5px;
                margin: 0 8px 0 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #F0F0F0; 
                &:hover { cursor: pointer; }
                &.copied { color: #333333; }
            }
            .secret-key-copy {
                background: transparent;
                border: none;
                padding: 0;
                box-shadow: none;
                &.copied .copy-icon::before { color: #333333; }
            }
        }
        .aha-form-group {
            input[type="password"] { padding: 0 35px 0 12px; }
            .view-pwd-icon {
                @include icomoon('eye', 10px); 
                &::before {
                    position: absolute;
                    right: 15px;
                    bottom: 14px;
                    color: rgba(0, 0, 0, 0.15);
                }
                &:hover { cursor: pointer; }
                &.active::before { color: rgba(0, 0, 0, 0.5); }
            }
        }
        .pwd-rule-wrapper {
            display: flex;
            align-items: center;
            width: max-content;
            height: 20px;
            margin: 0 5px 12px;
            padding: 0 5px;
            background-color: $grey-color1;
            border-radius: 10px;
            font-family: $roboto-regular;
            font-size: pxToRem(11px);
            line-height: 15px;
            text-align: left;
            color: $grey-color;
            @include icomoon('checkbox-marked-circle', 12px);
            &.pwd-rule-valid {
                background-color: $grey-color3;
                color: $green-color;
                animation: scale 0.3s ease-in-out 0.1s forwards;
                &::before { 
                    color: $green-color; 
                }
            }
            span { padding-left: 3px; }
        }
        .aha-login-wrapper {
            max-width: 280px;
            margin: 0 auto; 
            
            .fup-wrapper {
                input.form-control { min-width: auto !important;}
                .aha-form-group:nth-child(1) { margin-right: 5px; }
                .aha-form-group:nth-child(2) { margin-left: 5px; }
            } 
            .vc-link-wrapper {
                margin-top: 15px;
            }
        }
        .separator-wrapper {
            span { color: rgba(112, 112, 112, 0.5); }
            span::before,
            span::after {
                content: '';
                position: absolute;
                top: 8px;
                display: block;
                width: 120px;
                height: 1px;
                background: rgba(112, 112, 112, 0.5);
            }
            span::after { right: 0; }
            span::before { left: 0; }
        }
        .barcode-wrapper canvas {
            width: 107px !important;
            height: 107px !important;
        }
        .qr-code-wrapper canvas {
            height: 107px !important;
            width: 107px !important;
        }
        @include breakpoint(max1600) {
            padding: 20px 15px 8px;
        }
        .primary-btn span { line-height: normal; }
    }
    .signup-right-wrapper {
        width: 312px;
        height: auto;
        background-image: url(../images/reading-charts1.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 8px 8px 0;
        @include breakpoint(max1600) { 
            width: 265px; 
            background-position: center;
        }
    }
    @include breakpoint(max1600) {
        max-width: 742px;
        min-height: 520px;
        img {
            width: 80%;
        }
        .mb-25 {
            margin-bottom: 10px;
        }
        .toast-wrapper { padding: 8px 15px 8px 20px; }
    }
}
.contact-admin {
    @include breakpoint(max1600) {
        .login-info.mb-sm-15 {
            margin-bottom: 5px !important;
        }
    }
}
.ad-radio-group {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}
.link-expired-screen .mb-25 {
    margin-bottom: 25px;
}
