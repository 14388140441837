.aha-chat-wrapper{
    //ChannelModals.css
    section .modal-header {
        padding: 2rem 1.5rem;
      }
      
      .modal-body {
        /* background-color: #f0f1f2; */
      }
      
      .modal-form {
        background-color: #f0f1f2;
        text-align: center;
        padding: 0.76rem;
        position: sticky;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
      }
      
      .users-list {
        padding: 1rem;
      }
      
      .user-list-item {
        background-color: #fff;
        padding: 1rem 1rem 1rem 2rem;
      }
      
      .user-list-item:hover {
        background-color: #1e6dff;
        color: #fff;
      }
      
      .view-members .main-section {
        background-color: #f0f1f2;
        padding: 1rem;
      }
      
      .view-members .list {
        background-color: #fff;
        border-radius: 3px;
      }
      
      .view-members .list-header {
        border-bottom: 1px solid #9b9da5;
      }
      
      .view-members .row {
        display: flex;
        padding: 1rem;
      }
      
      .view-members .name {
        width: 50%;
        padding-left: 0.25rem;
      }
      
      .add-members #add-member,
      .edit-channel #edit-channel .add-channel-flow #add-channel-flow {
        display: flex;
        border-bottom: 1px solid #f0f1f2;
      }
      
      .edit-channel #edit-channel {
        flex-direction: column;
      }
      
      .add-members .input,
      .edit-channel .input,
      .add-channel-flow .input {
        width: 100%;
        margin: 1rem 0;
      }
      
      .add-members .input input,
      .edit-channel .input input,
      .add-channel-flow .input input {
        width: inherit;
      }
      
      .add-members .users-result .add-channel-flow li:hover {
        background: #1e6dff;
        color: white;
      }
      
      .add-members .user {
        padding: 0.5rem;
      }
      
      .add-members .users-result {
        margin-bottom: 1rem;
      }
      
      .edit-channel .radio-buttons {
        display: flex;
        flex-direction: column;
        padding: 1rem;
      }
      
      .edit-channel .radio-buttons span {
        padding: 0.5rem;
      }
      
      .view-details .container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
      }
      
      .view-details .row {
        display: flex;
        flex-direction: row;
        padding-bottom: 1rem;
      }
      
      .view-details .key {
        width: 30%;
      }
      
      .view-details .detail {
        color: #9b9da5;
        padding-left: 0.25rem;
      }
      
      .add-channel-flow .container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
      }
      
      .add-channel-flow .row {
        display: flex;
        flex-direction: row;
        padding-bottom: 1rem;
      }
      
      .add-channel-flow .key {
        width: 30%;
      }
      
      .add-channel-flow .detail {
        color: #9b9da5;
        padding-left: 0.25rem;
      }
      
      .edit-channel .radio-buttons span:nth-child(1)::after {
        content: " (administrators and moderators can add members)";
        color: #9b9da5;
      }
      
      .edit-channel .radio-buttons span:nth-child(2)::after {
        content: " (any member can add other members)";
        color: #9b9da5;
      }
      .ban-users-list {
        background-color: #fff;
        position: relative;
      }
      
      .ban-user-row {
        display: flex;
      }
      
      .ban-row {
        display: flex;
        padding: 1rem;
      }
      
      .ban-row-header {
        padding: 1rem;
        z-index: 2;
        background-color: #fff;
        position: sticky;
        top: 57.3125px;
        right: 0;
        left: 0;
        border-radius: 3px 3px 0 0;
        border-bottom: 0.5px solid #d4d5d8;
      }
      
      .ban-row-name {
        width: 50%;
      }
      
      .ban-row-role {
        width: 30%;
      }
      
      .ban-row-check {
        display: auto;
      }
      
      .ban-input input {
        width: 100%;
        position: relative;
      }
      
      .ban-input button {
        left: 13.5rem;
        top: 1rem;
      }
    //JoinMeetingModal.css
    
    .join-modal {
        text-align: center;
    }
    
    .join-header {
        margin-top: 2rem;
    }
    
    .response-buttons{
        margin: 0 auto;
        width: 50%;
        margin-top: 1rem;
    }
    
    .message-buttons{
        margin: 0 auto;
        width: 28%;
        margin-bottom: 2rem;
    }
    
    .join-button{
        margin: 0 0.5rem 0 5rem;
        background-color: #067000 !important;
    }
    
    .decline-button{
        margin: 0 0.5rem 0 5rem;
        color: #ffffff !important;
        background-color: #c52000 !important;
    }

    //NewChannelModal.css

    #new-channel-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
      }
      
      .new-channel-input {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      
      .new-channel-input input {
        width: inherit;
      }
      
      .ch-form-field-input {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto;
      }
      
      .ch-form-field-input .lbl {
        display: flex;
        padding: 1rem 0;
        flex-direction: column;
        margin: auto 0;
        min-width: 35%;
      }
      
      .ch-form-field-input .value {
        display: flex;
        width: 100%;
        margin: auto 0;
        padding: 1rem;
        flex-direction: column;
      }
      
      .ch-type-options span,
      .ch-mode-options span {
        padding: 5px 0;
      }
      
      .ch-type-options span:nth-child(1)::after {
        content: ' (only members can read and send messages)';
        color: #9b9da5;
      }
      
      .ch-type-options span:nth-child(2)::after {
        content: ' (non-members can read and send messages)';
        color: #9b9da5;
      }
      
      .ch-mode-options span:nth-child(1)::after {
        content: ' (administrators and moderators can add members)';
        color: #9b9da5;
      }
      
      .ch-mode-options span:nth-child(2)::after {
        content: ' (any member can add other members)';
        color: #9b9da5;
      }
    
    //SetCustomStatusModal.css
    #custom-status-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
      }
      
      .custom-status-form-field-input {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto auto 10pt;
      }
      
      .custom-status-form-field-input-div {
        min-width: 50%;
      }
      
      .custom-status-form-field-input .lbl {
        display: flex;
        padding: 1rem 0;
        flex-direction: column;
        margin-top: 0.5rem;
        min-width: 10%;
      }
      
      .custom-status-form-field-input .value {
        display: flex;
        width: 100%;
        margin: auto 0;
        padding-top: 1rem;
        padding-left: 0;
        flex-direction: column;
      }
      
      .custom-status-form-field-input .max-length-lbl {
        display: flex;
        font-size: 9pt;
      }

      //ChannelsWrapper.css

      .channel-list-header {
        display: flex;
        justify-content: space-between;
        padding: 0.25rem 0.5rem;
        border-bottom: 0.5px solid #d4d5d8;
      }
      
      button.create-channel-button {
        height: 2rem;
        width: 2rem;
      }
      
      .channel-list-header-title {
        padding-left: 1rem;
      }
      .channel-list-header,
      .channel-list-header-title {
        display: flex;
        align-items: center;
        cursor: unset;
      }
      
      ul.ch-popover-menu {
        backdrop-filter: none;
      }
      
      button.ch-sts-popover-toggle.ch-popover-toggle {
        right: auto;
        border-radius: inherit;
        margin: inherit;
        width: 100%;
        height: 100%;
      }
      
      button.ch-sts-popover-toggle.ch-popover-toggle:hover {
        background-color: #004ddb;
      }
      
      li.separator {
        margin: 0.5rem 0;
      }
    //Input.css
    
    .message-input-form {
        flex: auto;
        padding: 1rem 0 1rem 1rem;
      }
      
      span.text-input {
        display: flex;
        padding: 5px;
        flex-grow: 1;
      }
      
      .text-input input {
        width: 100%;
      }
      
      .message-input-container {
        border-top: 1px solid #e4e9f2;
        display: flex;
        flex-flow: row;
        margin-top: auto;
        margin-bottom: 0;
        min-height: 4rem;
      }
      
      .message-input-container .write-link.attach {
        margin: 1rem 1rem auto 0.5rem;
      }
      
      .message-input-container .attachment-preview {
        display: flex;
      }
      
      .message-input-container .attachment-preview span {
        margin: auto 0;
      }
      
      .message-input-container.join-channel-message {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    //LoginWithCognito.css
    form.signin-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    
    div.input.username-input, div.input.password-input {
        margin-left: -5rem;
        width: 100%;
        grid-template-columns: 18% 1fr;
    }
    
    div.input {
        margin-bottom: 2rem;
        text-align: left;
    }
    
    div.input span {
        width: 25rem;
    }
    
    .input input {
        width: 100%;
    }
    
    div.input span {
        width: 25rem;
    }
    
    .signin-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .or-span {
        font-size: .875rem;
    }
    
    //LoginWithCredentialExchangeService.css

    form.signin-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    
    div.input.access-token-input{
        margin-left: -5rem;
        width: 100%;
        grid-template-columns: 18% 1fr;
    }
    
    div.input {
        margin-bottom: 2rem;
        text-align: left;
    }
    
    div.input span {
        width: 25rem;
    }
    
    .input input {
        width: 100%;
    }
    
    div.input span {
        width: 25rem;
    }
    
    .access-token-submit-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .or-span {
        font-size: .875rem;
    }
    //Messages.css
    .message {
        margin: 0 1.5rem 0.5rem 0.5rem;
      }
      
      .message-list-container {
        background-color: #f0f1f2;
        overflow-y: scroll;
        position: relative;
      }
      
      .message-list-header {
        background-color: inherit;
        text-align: center;
        padding: 0.76rem;
        margin-bottom: 1rem;
        border-bottom: 0.5px solid #d4d5d8;
        position: sticky;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
      }
    //ChannelPresence.css

    .channel-members-list-header {
        background-color: inherit;
        text-align: center;
        padding: 0.75rem;
        margin-bottom: 0.5rem;
        border-bottom: 0.5px solid #d4d5d8;
        position: sticky;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
      }
     
     .channel-members-list-header-title {
       padding-left: 1rem;
     }
     
     .channel-members-list-item {
        padding: 0 8px 8px;
     }
     
     .channel-members-container {
        background-color: #f0f1f2;
        border-left: 1px solid #d4d5d8;
        height: 100%;
        overflow-y: scroll;
        min-width: 12rem;
     }

    //Index.css     

    .typing-indicator {
        display: block;
        width: 100%;
        height: 100%;
        padding-bottom: 0.5rem;
    }
    
    .typing-indicator span {
        background-color: gray;
        border-radius: 50%;
        display: block;
        float: left;
        height: 5px;
        width: 5px;
        margin: 0 1px;
        opacity: 0.4;
    }
    
    .typing-indicator span:nth-child(1) {
        animation: 1s blink infinite 334ms;
    }
    
    .typing-indicator span:nth-child(2) {
        animation: 1s blink infinite 667ms;
    }
    
    .typing-indicator span:nth-child(3) {
        animation: 1s blink infinite 1s;
    }
    
    @keyframes blink {
        50% {
            opacity: 1;
        }
    }

    //Channel-styles.css
    .user-block {
        /* Custom style */
        right: 0;
        display: inline-block;
        position: absolute;
        padding-right: 1rem;
      }
      
      .user-block a {
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: white;
        padding-left: 24px;
      }
      
      .user-info {
        position: relative;
        display: inline-block;
        text-decoration-line: none;
      }
      
      /* Tooltip text */
      .user-info .tooltiptext {
        visibility: hidden;
        background-color: black;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-left: -100px;
        position: absolute;
        z-index: 1;
      }
      
      /* Show the tooltip text when you mouse over the tooltip container */
      .user-info:hover .tooltiptext {
        visibility: visible;
      }
      
      .channel-content-container-grid {
        display: grid;
      }
      
      .toggles {
        backdrop-filter: none;
      }
      
      .channel-content-container {
        grid-template-columns: 3fr 1fr;
        height: 100%;
        backdrop-filter: none;
      
      }
      
      .messaging-container {
        background-color: #f0f1f2;
        height: 100%;
        overflow-y: scroll;
      }
    
      //Signin - styles.css

      .signin-container {
        text-align: center;
        width: 25rem;
        margin: 0 auto;
        margin-top: 6.5rem;
    }
    //utilities-styles.css
    
    li span.date-header {
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        display: flex;
      }
    //Chat.css
    h1.app-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        font-size: 1.5rem;
        color: #fff;
      }
      
      .main-page-content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
      }
      
      .messaging-container,
      .placeholder {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
      
      .placeholder {
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }
      
      .Messages-list {
        padding: 20px;
        width: 100%;
        margin: 0 auto;
        flex-grow: 1;
        overflow-y: scroll;
      }
      
      .parent {
        display: inline-grid;
        width: 100%;
        padding: 10px 10px 10px 10px;
      }
      
      .child {
        display: flex;
        width: 100%;
        justify-content: space-between;
        max-width: 900px;
        margin: 10px auto 40px;
      }
      
      .notification {
        position: absolute;
        width: 100vw;
        top: 4rem;
      }
      
      .notification div {
        max-width: 50rem;
        width: fit-content;
        align-items: center;
      }
}