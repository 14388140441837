//Color variables
$black-color            : #000000;
$black-color2           : #2D3038;
$black-color3           : #333333;
$black-color4           : #191C1D;
$white-color            : #FFFFFF;
$white-color2           : #f8f9fa;
$white-color3           : #FAFBFC;
$white-color4           : #F6FDFF;
$white-color5           : #83A7AD;
$white-color6           : #799EA4;
$white-color7           : #7399A0;
$white-color8           : #FCFCFC;
$white-color9           : #F8F9FA;
$light-black-color      : rgba(18, 18, 18, 0.87);
$label-color            : #121212;
$input-border-color     : rgba(18, 18, 18, 0.24);
$dark-grey-color        : #313131;
$grey-color             : #666666;
$light-grey-color       : #CCCCCC;
$grey-color1            : #F0F0F0;
$grey-color2            : #a7a7a7;
$grey-color3            : #D2FCDF;
$grey-color4            : #767676;
$grey-color5            : #6E6E6E;
$grey-color6            : #707070;
$grey-color7            : #C6C6C6;
$grey-color8            : #859099;
$grey-color9            : #F2F2F2;
$grey-color10           : #525252;
$grey-color11           : #919191;
$grey-color12           : #979797;
$grey-color13           : #CCD9DB;
$grey-color14           : #EBEBEB;
$grey-color15           : #323232;
$grey-color16           : #EAEAEA;
$grey-color17           : #5F6064;
$grey-color18           : #DEE2E5;
$grey-color19           : #6c757d;
$grey-color20           : #D4D4D4;
$green-color            : #0D6E2B;
$green-color2           : #CAF7DA;
$green-color3           : #004E5D;
$green-color4           : #006B57;
$green-color5           : #BBEDCC;
$green-color6           : #006174;
$green-color7           : #005D6F;
$green-color8           : #F0F7F6;
$light-blue-color       : #6D66DC;
$light-blue-color1      : #6E7DAB;
$light-blue-color2      : #EDECFB;
$light-blue-color3      : #B5B2ED;
$dark-blue-color        : #1E3D71;
$dark-blue-color1       : #34345B;
$blue-color             : #382DC8;
$link-blue-color        : #3127A5;
$blue-color1            : #0B2032;
$blue-color2            : #162C52;
$blue-color3            : #BBDAEC;
$blue-color4            : #5796C9;
$blue-color5            : #EAEAFA;
$blue-color6            : #526A92;
$blue-color7            : #86b7fe;
$blue-color8            : #DCE1E9;
$blue-color9            : #22505A;
$blue-color10           : #10086A;
$blue-color11           : #1B1B1D;
$blue-color12           : #CFECEC;
$blue-color13           : #C5E3E5;
$blue-color14           : #B3D4D6;
$blue-color15           : #305DA8;
$blue-color16           : #6D81A3;
$blue-color17           : #88b2ff;
$blue-color18           : #7A97C7;
$blue-color19           : #bbdaec;
$blue-color20           : #e0fcfb;
$red-color              : #C6262D;
$red-color2             : #d5d3f5;
$red-color3             : #CF3F3D;
$gradient-red-color     : #c7252c;
$pink-color             : #F5CCD4;
$pink-color2            : #F7F2F4;
$disabled-color         : #DEDEDE;
$swamp-color            : #001F26;
$deepsea-green-color    : #0F5C6B;
$cyprus-color           : #003641;
$orient-color           : #00687B;
$tropicalrainforest-color: #001F26;
$seafoam-color          :#E0FCFB;
$siren-color            :#8E0246;
$affair-color           :#723F8D;
$cardinal-color         :#c6262d;
$purple-color           :#31044A;
$purple-color2          :#E4DFFE;
$purple-color3          :#D5D3F5;
$purple-color4          :#FCF7FF;
$yellow-color           :#FFFAAC;
$dark-yellow-color      :#FAB96E;

// font-size variables
$font-size8             : 8px;
$font-size10            : 10px;
$font-size11            : 11px;
$font-size12            : 12px;
$font-size13            : 13px;
$font-size14            : 14px;
$font-size16            : 16px;
$font-size24            : 24px;

// font-family variables
$roboto-light          : 'robotolight', sans-serif;
$roboto-regular        : 'robotoregular', sans-serif;
$roboto-medium         : 'robotomedium', sans-serif;
$roboto-bold           : 'robotobold', sans-serif;

//Font face rules
@font-face {
    font-family: 'robotolight';
    src: url('../fonts/Roboto-Light.ttf') format('ttf');
    src: url('../fonts/Roboto-Light.woff') format('woff');
    src: url('../fonts/Roboto-Light.woff2') format('woff2'); 
    font-weight: normal;
    font-style: normal;   
    font-display: swap;
}
@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/Roboto-Regular.ttf') format('ttf');
    src: url('../fonts/Roboto-Regular.woff') format('woff');
    src: url('../fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/Roboto-Medium.ttf') format('ttf');
    src: url('../fonts/Roboto-Medium.woff') format('woff');
    src: url('../fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;  
    font-display: swap;
}
@font-face {
    font-family: 'robotobold';
    src: url('../fonts/Roboto-Bold.ttf') format('ttf');
    src: url('../fonts/Roboto-Bold.woff') format('woff');
    src: url('../fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;  
    font-display: swap;
}
//Icomoon font face rule
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?46vubw');
    src:  url('../fonts/icomoon.eot?46vubw#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?46vubw') format('truetype'),
      url('../fonts/icomoon.woff?46vubw') format('woff'),
      url('../fonts/icomoon.svg?46vubw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// @function pxToRem($pxValue) {
//     @return math.div($pxValue, 16px) * 1rem;
// }


