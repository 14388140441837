//Input loader animation
.ad-loading-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99999;
}
.loader {
  display: none;
  width: 22px;
  height: 22px;
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid $white-color;
  border-left: 5px solid $white-color;
  border-bottom: 5px solid $white-color;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  &.ad-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top-color: #1e3d71;
    border-left-color: #1e3d71;
    border-bottom-color: #1e3d71;
    z-index: 999999;
  }
  &.ad-loader-report {
    border-top-color: #1e3d71;
    border-left-color: #1e3d71;
    border-bottom-color: #1e3d71;
  }
}
//Key frames
//for password rule valid tag
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes successscale {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  20% {
    transform: scale(0.8, 0.8);
    opacity: 0.5;
  }
  40% {
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    transform: scale(1, 1);
    opacity: 1;
  }
  80% {
    transform: scale(0.8, 0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
//Info toast animation
@keyframes toast-anim {
  0% {
    transform: translateY(50%);
    opacity: 0.1;
  }
  50% {
    transform: translateY(20%);
    opacity: 0.5;
  }
  75% {
    transform: translateY(10%);
    opacity: 0.7;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
//Info toast styles
.toast-wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
  max-width: 438px;
  padding: 8px 15px 18px 20px;
  border-radius: 10px;
  background: $white-color;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  transition: 0.5s;
  animation: toast-anim 0.5s linear;
  .toast-header {
    padding: 0;
    border: none;
    background: transparent;
    justify-content: flex-end;
  }

  .close-icon {
    line-height: 1;
    border: none;
    background: none;
    outline: 0;
    &::before {
      color: rgba(0, 0, 0, 0.5);
    }
    &:hover {
      cursor: pointer;
    }
  }
  .toast-info-details {
    flex: 0 0 95%;
    max-width: 95%;
    padding: 0;
    text-align: left;
    line-height: 1;
    p {
      line-height: 16px;
      color: $dark-blue-color;
    }
    .checkmark-with-circle {
      line-height: 1;
      &::before {
        color: $green-color;
      }
    }
  }
}
.toaster-container {
  z-index: 100001 !important;
  transition: 0.5s;
  animation: toast-anim 0.5s linear;
  & > div {
    bottom: 0;
    align-items: flex-end;
  }
  .toaster-wrapper {
    max-width: 438px;
    min-width: 438px;
    border-radius: 10px;
    background-color: #0B2032;
    color: #fff;
    font-family: $roboto-medium;
    & > div { 
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      > :nth-of-type(2) {
        flex: 0 0 95%;
        max-width: 95%;
      }
    }
  }
  .export-file-toaster-wrapper {
    max-width: 300px;
    min-width: 300px;
    border-radius: 10px;
    background-color: $green-color4;
    font-family: $roboto-medium;
    .file-toaster {
      padding-top: 10px;
    }
    .toaster-close {
      position: absolute;
      right: 10px;
      top: 8px;
    }
    .custom-toast-msg-text {
      padding-right: 20px;
      color: #fff;
    }
    .checkmark-with-circle,
    .close-icon {
      &::before {
        color: #fff !important;
      }
    }
    .checkmark-with-circle {
      &::before {
        font-size: pxToRem(18px);
      }
    }
    & > div { 
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      > :nth-of-type(2) {
        flex: 0 0 95%;
        max-width: 95%;
      }
    }
    &.export-file-info-wrapper {
      background-color: $blue-color4;
    }
  }
  .toaster-close {
    flex: 0 0 100%;
    text-align: right;
    line-height: 1;
    & .close-icon:hover { cursor: pointer; }
    &.pt-toaster-close {
      & .close-icon::before {
        color: $red-color3;
      }
    }
  }
  .toast-info-details {   
    padding: 0;
    text-align: left;
    line-height: 1;
    p {
      line-height: 16px;
    }
    .checkmark-with-circle {
      line-height: 1;
      &::before {
        color: $green-color;
      }
    }
  }
}
.user-active-info {
  position: relative;
  display: block;
  height: 138px;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.toast-container-new {
  .toast-wrapper {
      background-color: #0B2032;
      .toast-info-details p { overflow: hidden; color: #fff; }
      .close-icon::before { color: #859099; }
  }
  .st-toast-wrapper {
    background-color: #DEFFE6;
    .toast-info-details p,
    .close-icon::before { 
      color: #1B1B1D !important; 
    }
    .toast-info-details p { font-family: $roboto-medium; }
  }
}
.pt-toast-container { 
  z-index: 999;
  right: 30px;
  top: 75px;
    .pt-toast-wrapper {
      min-width: 415px;
      padding: 15px 15px;
      background-color: #ffdede;
    }
    .network-error-icon:before { font-size: 26px;}
    p {
      text-align: left;
      line-height: 16px;
      font-family: $roboto-medium;
    }
}
.input-validation-msg {
  line-height: 12px;
}
//Toggle switch
.ad-toggle-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 12px;
   
    &.radio-disabled {
      .slider { 
        opacity: .5;
        cursor: default; 
      }
    }
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c6c6c6;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0;
    bottom: -2px;
    background-color: white;
    -webkit-transition: ease-out 0.3s;
    transition: ease-out 0.3s;
    box-shadow: 0px 3px 6px #00000029;
  }

  input:checked + .slider {
    background-color: $dark-blue-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $dark-blue-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 13px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  &.radio-disabled {
    .ad-switch { 
      opacity: .5;
    }
  }
}

//Custom Scroll Bar For Chrome

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: $grey-color6;
  border-right: 4px solid transparent;
}

//syncfusion dropdown with checkbox
.grid-common-wrapper {
  .e-grid {
    .e-dlg-content {
      position: relative;
      padding: 0;
    }
    .e-footer-content {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 10px;
      border-color: $grey-color7;
      .e-btn {
        min-width: 72px;
        height: 40px;
        border-radius: 5px;
        font-size: pxToRem(14px);
        font-family: $roboto-medium;
      }
      .e-primary.e-flat:disabled,
      .e-primary.e-flat:disabled:hover,
      .e-primary.e-flat:disabled:focus {
        border: none;
        color: $grey-color5;
        background: $grey-color9;
      }
      .e-flat {
        background-color: transparent;
        border-color: $dark-blue-color;
        color: $dark-blue-color;
        &:hover {
          background-color: transparent;
          color: $blue-color2;
          border-color: $blue-color2;
        }
        &:focus {
          border: 1px solid $blue-color4;
          box-shadow: 0 0 0 0.25rem $blue-color3;
        }
      }
      .e-primary.e-flat {
        background-color: $dark-blue-color;
        border-color: $grey-color6;
        color: $white-color;
        &:hover {
          background-color: $blue-color2;
          color: $white-color;
        }
      }
    }
    .e-css.e-checkbox-wrapper .e-frame {
      position: relative;
      width: 18px;
      height: 18px;
      line-height: 10px;
      padding: 2px 0;
      &.e-icons.e-uncheck {
        border: 3px solid rgba(0,0,0,.6);
        &:hover {
          border-color: $grey-color12;
        }
      }
    }
    .e-ftrchk {
      &:hover {
        background-color: $grey-color9;
      }
      .e-label {
        font-family: $roboto-regular;
        font-size: pxToRem(14px);
        line-height: 1.1rem;
        margin-left: 12px !important;
        color: $label-color;
        letter-spacing: 0.25px;
      }
    }
    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
      background-color: $dark-blue-color;
      border: none;
      border-radius: 2px;
      &:hover {
        background-color: $blue-color2;
      }
      &::before {
        content: "\e727";
        position: absolute;
        top: 4px;
        left: 0;
        right: 0;
        font-size: pxToRem(10px);
      }
    }
    .e-checkbox-wrapper .e-frame.e-stop,
    .e-css.e-checkbox-wrapper .e-frame.e-stop {
      background-color: $dark-blue-color;
      border: none;
      &::before {
        position: absolute;
        top: 4px;
        left: 5px;
      }
      &:hover,
      &:focus {
        background-color: $blue-color2;
      }
    }
    .e-pager {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .e-pagercontainer {
        height: 40px;
        display: flex;
        align-items: center;
        border-color: $grey-color7;
        border-radius: 5px;
      }
      .e-numericitem {
        color: $dark-blue-color;
        font-size: pxToRem(14px);
        font-family: $roboto-medium;
        border-right-color: $grey-color7;
      }
      .e-numericitem,
      .e-first.e-icons.e-icon-first,
      .e-icons {
        width: 40px;
        height: 40px;
        padding: 0;
        text-align: center;
        line-height: 40px;
      }
      .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active) {
        padding: 0;
      }
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default,
      .e-last.e-icons.e-icon-last.e-lastpage.e-pager-default {
        color: $dark-blue-color;
        text-align: center;
      }
      .e-parentmsgbar {
        padding-top: 0;
        margin-top: -12px;
        color: $grey-color4;
        font-size: pxToRem(14px);
        font-family: $roboto-medium;
      }
      .e-icons {
        color: $dark-blue-color;
        font-weight: 600;
      }
      .e-first.e-icons.e-icon-first.e-firstpagedisabled.e-disable,
      .e-prev.e-icons.e-icon-prev.e-prevpagedisabled.e-disable {
        color: $grey-color5;
      }
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default,
      .e-last.e-icons.e-icon-last.e-lastpage.e-pager-default {
        color: $dark-blue-color;
      }
    }
    .e-pager .e-currentitem,
    .e-pager .e-currentitem:hover {
      background-color: $dark-blue-color;
      color: $white-color;
      border-color: $dark-blue-color;
    }
  }
}

//Modal PopUp Dropdown 
.modal-popup-dropdown.e-popup.e-ddl{
  overflow: hidden;
  padding: 8px 10px;
  box-shadow: 0px 3px 6px rgba(0,0,0,.6);
  .e-filter-parent{
    padding: 5px 0;
    border-radius: 0;
    box-shadow: none;
  }
  .e-dropdownbase .e-list-item{
    font-family: $roboto-regular;
    padding: 2px 0;
    margin-top:8px;
    &:hover,&.e-active,&.e-item-focus{
      color:$dark-grey-color;
      background-color: rgba(0,0,0,0.08);
    }
  }
}

//User Status Chips
.user-status-wrapper {
  .e-chip-list .e-chip {
    padding: 16px 12px;
    border-radius: 8px;
    .e-chip-text {
      font-family: $roboto-regular;
      font-size: pxToRem(13px);
      padding: 3px 12px 0;
    }
  }
  //Active User Chip
  .e-chip-list .e-chip.e-user-active {
    background-color: $green-color2;
    border: none;
    @include icomoon("check-circle", 20px);
    .e-chip-text {
      color: $green-color;
    }
  }
  //Invited User Chip
  .e-chip-list .e-chip.e-user-invited {
    background-color: transparent;
    border: 2px solid $green-color2;
    @include icomoon("Invited", 20px);
    .e-chip-text {
      color: $green-color;
    }
  }
  //Locked User Chip
  .e-chip-list .e-chip.e-user-locked {
    background-color: $pink-color;
    border: none;
    @include icomoon("Lock", 20px);
    .e-chip-text {
      color: $red-color;
    }
  }
  //Deactivated User Chip
  .e-chip-list .e-chip.e-user-deactivated {
    background-color: $grey-color7;
    border: none;
    @include icomoon("Deactivated", 20px);
    .e-chip-text {
      color: $grey-color10;
    }
  }
  //Pending User Chip
  .e-chip-list .e-chip.e-user-pending {
    background-color: $dark-yellow-color;
    border: none;
    @include icomoon("error-solid", 20px);
    .e-chip-text {
      color: $label-color;
    }
    &::before {
      color: $label-color !important;
    }
  }
}

//Tool tip common styles
.tooltip-inner {
  background-color: #333333;
  border-radius: 8px;
  font-family: "robotoregular";
  font-size: 11px;
  line-height: 13px;
  padding: 7px 9px;
}
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #333333;
}
//Add user success animation
.ad-modal-success-wrapper {
  z-index: 1057;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  .adduser-success {
    background-image: url(../images/success-icon.svg);
    background-repeat: no-repeat;
    height: 145px;
    width: 145px;
    animation: successscale 0.8s ease-in-out 0.3s forwards;
  }
}
.auth-success-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 0 0 138px;
  width: 138px;
  height: 138px;
  margin: 0 auto 30px;
  background-color: rgba(109, 102, 220, 0.1);
  border-radius: 50%;
}
.dark-grey-color { color: $grey-color17 ; }
.label-color { color: $label-color; }
.hide {
  display: none !important;
}
.test-badge { 
  width: 100%;
  height: 18px;
  background-image: url('../images//visual-badge.svg');
  background-position: left;
  background-size: contain;
  background-color: #C6262D;
}
.test-badge.test-badge-preprod { 
  // position: absolute;
  // z-index: 1;
  background-image: url('../images/visual-badge-preprod.svg');
}
// Input Attachment feild stylea
.do-attachment-wrapper {
  position: relative;
  label {
    display: block;
  }
  input { font-size: 0 !important; }
  input,
  textarea {
    height: 40px;
    min-width: 240px;
    width: 100%;
    font-size: pxToRem(14px);
    border-radius: 4px;
    border: 1px solid rgba(18,18,18,0.2);
    padding-left: 10px;
    padding-right: 30px;
    letter-spacing: 0.18px;
    color: $label-color !important;
    font-family: $roboto-regular;
    &:disabled {
      background-color: $grey-color9;
      color: $label-color;
    }
    &:active,
    &:focus {
      box-shadow: 0 0 0 4px rgb(187, 218, 236);
      outline: none;
      border-color: $blue-color7;
    }
    &:hover {
      border-color: $grey-color4;
      color: $grey-color4;
    }
    &:disabled:hover{
      border-color:$grey-color7;
    }
  }
  input.attachment-file {
    position: relative;
    padding-top: 10px;
    background-color: $white-color;

    &::-webkit-file-upload-button {
        display: none;
    }

    @include icomoon("logout", 14px);

    &:before {
        position: absolute;
        top: 12px;
        right: 9px;
        display: block;
        transform: rotate(-90deg);
        color: $black-color;
    }
  }
  input.facility-attachment-file {
    position: relative;
    background-color: $white-color;

    &::-webkit-file-upload-button {
        display: none;
    }

    @include icomoon("logout", 14px);

    &:before {
        position: absolute;
        top: 12px;
        right: 9px;
        display: block;
        transform: rotate(-90deg);
        color: $black-color;
    }
  }
  .do-selected-file-facility {
    position: absolute;
    top: 38px;
    left: 4px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
  .do-selected-file {
    position: absolute;
    top: 28px;
    left: 12px;
    max-width: 295px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
  &.co-location-wrapper {
    .do-selected-file,
    .co-location-close {
      z-index: 5;
    }
    .do-selected-file {
      top: -29px;
      max-width: 200px;
      color: rgba(18, 18, 18, 0.87);
      font-size: 0.875rem;
      line-height: 1.2;
    }
   .co-location-close {
      position: absolute;
      top: -30px;
      right: 12px;
      &:hover { cursor: pointer; }
      &:before { color: #757575;}
   }
  }
  &.requi-attachment-wrapper {
    .do-selected-file-facility {
      left: 11px;
      max-width: 200px;
    }
    &.with-datetime .do-selected-file-facility {
      top: 23px;
    }
  }
}
.view-attachment {
  padding-bottom: 20px;

  p {
      display: flex;
      font-family: $roboto-regular;
      font-size: pxToRem(14px);
      font-style: italic;
      color: $light-blue-color;

      .view-btn {
          cursor: pointer;
      }

      .file-name {
          width: 415px;
          padding-right: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          overflow: hidden;
      }
  }
}
.courier-value-selected,
.courier-value {
  min-height: 40px;
}
.courier-value-selected {
   svg, input, button  { font-size: 0; display: none;}
    color: rgba(18, 18, 18, 0.87);
    font-size: 0.875rem;
    border: 1px solid rgba(18, 18, 18, 0.2);
    border-radius: 4px;
    font-family: "robotoregular", sans-serif;
    .mapboxgl-ctrl-geocoder { border: none !important };
    span.courier-value {
      height: 36px;
      padding: 6px 35px;
    }
}
.icon-tracker-facility-default{
  background-image: url('../images/facility-default.svg');
}
.icon-tracker-facility-toggle{
  background-image: url('../images/facility-toggle.svg');
 }
.icon-tracker-hospital-default{
  background-image: url('../images/hospital-default.svg');
}
.icon-tracker-hospital-toggle{
  background-image: url('../images/hospital-toggle.svg');
}
.icon-tracker-phleb-default{
  background-image: url('../images/phleb-default.svg');
}
.icon-tracker-phleb-toggle{
  background-image: url('../images/phleb-toggle.svg');
}
.icon-tracker-lab-default{
  background-image: url('../images/lab-default.svg');
}
.icon-tracker-lab-toggle{
  background-image: url('../images/lab-toggle.svg');
}
//Maintainance Page styles
.main-wrapper.mt-main-wrapper {
  background-position: -90px calc(100% + 70px), center;
  background-size: 35%, cover;
  .mt-aha-logo {
    max-width: 175px;
  }
  .mt-info-section p {
    max-width: 75%;
    margin: auto;
  }
  p:nth-child(2) { 
    padding-top: 30px; 
    padding-bottom: 8px;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
.st-value-tooltip {
  max-width: 450px;
  &.st-tooltip-btm.tooltip .tooltip-arrow::before { 
    border-top-color: #8A8A8A; 
    border-left-color: transparent;
  }
  &.tooltip .tooltip-arrow::before {
    border-left-color: #8A8A8A;
    border-right-color: #8A8A8A;
  }
  .tooltip-inner {
      max-width: 450px;
      padding: 2px 10px;
      background-color: #8A8A8A;
      border-radius: 4px;
      text-align: left;
      p {
          font-size: pxToRem(14px);
          line-height: 1.2;
          color: #fff;
      }
  }
}
//Class created for icomoon classses
.checkmark-with-circle {
  @include icomoon("checkbox-marked-circle", 20px);
  &.auth-user-active::before,
  &.auth-success::before {
    margin-left: 10px;
    font-size: 120px;
    color: $light-blue-color;
  }
}
.close-with-circle {
  @include icomoon("close-circle-outline", 20px);
  &.link-invalid::before {
    font-size: 125px;
    color: $light-blue-color;
  }
}

.close-icon {
  @include icomoon("close", 12px);
}

.delete-icon {
  @include icomoon("delete", 18px);
  &::before {
     color: $light-blue-color !important;
  }
  &:hover { cursor: pointer}
}

.icon-auth-user-active {
  @include icomoon("auth-user-active", 50px);
}
.icon-sand-complete {
  @include icomoon("timer-sand-complete", 115px);
  margin-top: -13px;
}
.icon-add-user {
  @include icomoon("person-add", 16px);
  line-height: normal;
}
.icon-non_service {
  @include icomoon("non-service", 16px);
  line-height: normal;
}
.icon-add-hospital {
  padding-top: 1px;
  line-height: normal;
  @include icomoon("Hospital-agenda", 16px);
  &::before { color: $light-blue-color !important; }
}
.icon-add-lab {
  padding-top: 1px;
  line-height: normal;
  @include icomoon("flask", 16px);
  &::before { color: $light-blue-color !important; }
}
.icon-care-facility {
  @include icomoon("care-facility", 16px);
  line-height: normal;
}
.copy-icon {
  @include icomoon("copy-mob", 18px);
}
.info-icon {
  @include icomoon("info", 12px);
  &.modal-info-icon {
    padding-left: 5px;
    vertical-align: middle;
  }
}
.plus-icon {
  @include icomoon("plus", 24);
}
.copy-calendar {
  @include icomoon('copy-calendar', 18px);
}
.confirmation-icon {
  @include icomoon("error-outline", 92px)
}
.network-error-icon {
  @include icomoon("error-outline", 22px);
  &::before {
    color: $red-color3 !important;
  }
}
.success-icon {
  @include icomoon("rounded-checkmark-with-circle", 88px)
}
.check-in {
  @include icomoon("check-in", 24px)
}
.check-out {
  @include icomoon("check-out", 24px)
}
.spin {
  @include icomoon("spin", 24px);
  &::before { color: #191C1D !important}
}
.download {
  @include icomoon("download-solid", 15px)
}
.stat-help-icon {
  @include icomoon("help-new", 20px);
  &:hover { cursor: pointer; }
}
.mob-back {
  @include icomoon("mob-back", 19px);
}
.add-order-new {
  @include icomoon("add-order-new", 19px);
}
.enroute-home {
  @include icomoon("home-route", 16px);
}
.enroute-icon {
  @include icomoon("route", 16px);
}
.report-icon {
  @include icomoon("monitoring", 80px);
}
.msgsend-icon {
  @include icomoon("msg-send", 80px);
}
.assignment-confirm {
  @include icomoon("assignment-confirm", 92px);
}
// .service-icon {
//   @include icomoon("arrow-down", 5px);
// }
.menu-open {
  @include icomoon("menu-open", 20px);
}
.reset-all {
  @include icomoon("reset-all", 15px);
}
.filter-expand {
  @include icomoon("filter-expand", 12px);
}
.scheduled-already-icon {
  @include icomoon("scheduled-already", 27px);
  &::before { color: $white-color !important;}
}
.filter-icon {
  @include icomoon("filter", 12px);
}
.exclamation-with-circle {
  @include icomoon("error-outline", 18px);
  &:before {
    color: $white-color !important;
  }
}
.cus-search-icon {
  @include icomoon("Search", 16px);
  &::before { color: #757575}
}
.cus-clear-icon {
  @include icomoon("mob-close", 13px);
  &::before { color: #919191 ;}
}
//Icomoon icons
.icon-checkbox-marked-circle:before {
  content: "\e905";
  color: #666;
}
.icon-eye:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-close-circle-outline:before {
  content: "\e908";
  color: #c6262d;
}
.icon-timer-sand-complete:before {
  content: "\e909";
  color: #6d66dc;
}
.icon-left-arrow:before {
  content: "\e90a";
  color: #6d66dc;
}
.icon-copy-solid:before {
  content: "\e90b";
}
.icon-Chat:before {
  content: "\e90c";
  color: #859099;
}
.icon-Schedule:before {
  content: "\e90d";
  color: #859099;
}
.icon-Maps:before {
  content: "\e90e";
  color: #859099;
}
.icon-Admin:before {
  content: "\e90f";
  color: #859099;
}
.icon-Lock:before {
  content: "\e910";
  color: #c6262d;
}
.icon-Deactivated:before {
  content: "\e911";
  color: #525252;
}
.icon-Invited:before {
  content: "\e912";
  color: #0d6e2b;
}
.icon-person-add:before {
  content: "\e913";
  color: #382dc8;
}
.icon-verified-user:before {
  content: "\e914";
  color: #dce1e9;
}
.icon-Search:before {
  content: "\e915";
  color: #919191;
}
.icon-check-circle:before {
  content: "\e916";
  color: #0d6e2b;
}
.icon-account-circle:before {
  content: "\e917";
  color: #666;
}
.icon-close-circle-outline-blue:before {
  content: "\e918";
  color: #6d66dc;
}
.icon-filter:before {
  content: "\e919";
  color: #919191;
}
.icon-app-shortcut:before {
  content: "\e91a";
  color: #6d66dc;
}
.icon-copy-mob:before {
  content: "\e91b";
  color: #ccc;
}
.icon-arrow-downward:before {
  content: "\e91c";
}
.icon-arrow-upward:before {
  content: "\e91d";
}
.icon-fingerprint:before {
  content: "\e91e";
  color: #6d66dc;
}
.icon-arrow-down:before {
  content: "\e91f";
  color: #666;
}
.icon-care-facility:before {
  content: "\e920";
  color: #382dc8;
}
.icon-info:before {
  content: "\e921";
  color: #1e3d71;
}
.icon-arrow-down-outlined:before {
  content: "\e922";
  color: #666;
}
.icon-three-vertical-dots:before {
  content: "\e923";
}
.icon-hamburger:before {
  content: "\e924";
}
.icon-drop-off:before {
  content: "\e925";
  color: rgba(30, 61, 113, 0.65);
}
.icon-mob-back:before {
  content: "\e926";
}
.icon-mob-close:before {
  content: "\e927";
}
.icon-help:before {
  content: "\e928";
  color: #44474f;
}
.icon-logout:before {
  content: "\e929";
  color: #44474f;
}
.icon-Checkmark:before {
  content: "\e92a";
  color: #fff;
}
.icon-Minus:before {
  content: "\e92b";
  color: #fff;
}
.icon-flask:before {
  content: "\e92c";
  color: #10046a;
}
.icon-Courier:before {
  content: "\e92d";
  color: #105c6b;
}
.icon-Hospital:before {
  content: "\e92e";
  color: #10056a;
}
.icon-routine-draw:before {
  content: "\e92f";
  color: #f6fdff;
}
.icon-home-health:before {
  content: "\e930";
  color: #ffecf0;
}
.icon-on-call:before {
  content: "\e931";
  color: #f1fff9;
}
.icon-scheduled-already:before {
  content: "\e933";
  color: #004e5d;
}
.icon-drag:before {
  content: "\e932";
  color: #83a7ad;
}
.icon-rounded-checkmark-with-circle:before {
  content: "\e934";
  color: #0d6e2b;
}
.icon-rounded-close-with-circle:before {
  content: "\e935";
  color: #c6262d;
}
.icon-direction:before {
  content: "\e936";
  color: #3127a5;
}
.icon-check-in:before {
  content: "\e937";
  color: #f6fdff;
}
.icon-check-out:before {
  content: "\e938";
  color: #fff;
}
.icon-2x-user:before {
  content: "\e939";
  color: #fff;
}
.icon-hyphen-dark:before {
  content: "\e93a";
}
.icon-delete-disabled:before {
  content: "\e93b";
  color: #fff;
}
.icon-delete:before {
  content: "\e93c";
  color: #fff;
}
.icon-dropoff-new:before {
  content: "\e93d";
  color: #fff;
}
.icon-Hospital-agenda:before {
  content: "\e93e";
  color: #31044a;
}
.icon-edit:before {
  content: "\e93f";
}
.icon-single-user:before {
  content: "\e940";
  color: #fff;
}
.icon-leave-chat:before {
  content: "\e941";
  color: #44474f;
}
.icon-refresh:before {
  content: "\e942";
}
.icon-plus:before {
  content: "\e943";
  color: #919191;
}
.icon-msg-send:before {
  content: "\e944";
  color: #6e6e6e;
}
.icon-check-black:before {
  content: "\e945";
}
.icon-group-chat:before {
  content: "\e946";
  color: #fff;
}
.icon-copy-calendar:before {
  content: "\e947";
  color: #fff;
}
.icon-error-outline:before {
  content: "\e948";
  color: #7f79e0;
}
.icon-download-solid:before {
  content: "\e949";
}
.icon-image-gallery:before {
  content: "\e94a";
  color: #6d66dc;
}
.icon-android_camera:before {
  content: "\e94b";
  color: #6d66dc;
}
.icon-history-icon:before {
  content: "\e94c";
  color: #6d66dc;
}
.icon-personal-injury:before {
  content: "\e94d";
  color: #6d66dc;
}
.icon-car-crash:before {
  content: "\e94e";
  color: #6d66dc;
}
.icon-crisis-alert:before {
  content: "\e94f";
  color: #6d66dc;
}
.icon-autorenew:before {
  content: "\e950";
}
.icon-assignment-late:before {
  content: "\e951";
}
.icon-visibility:before {
  content: "\e952";
}
.icon-hourglass-bottom:before {
  content: "\e953";
}
.icon-receipt:before {
  content: "\e954";
  color: #3127a5;
}
.icon-timer:before {
  content: "\e955";
}
.icon-photo-library:before {
  content: "\e956";
  color: #6d66dc;
}
.icon-calendar_timer:before {
  content: "\e957";
}
.icon-sticky-note:before {
  content: "\e958";
  color: #6d66dc;
}
.icon-file-upload:before {
  content: "\e959";
}
.icon-cancel-order:before {
  content: "\e95a";
  color: #6d66dc;
}
.icon-flash-off:before {
  content: "\e95b";
}
.icon-flash:before {
  content: "\e95c";
}
.icon-help-new:before {
  content: "\e95d";
  color: #5f6064;
}
.icon-export:before {
  content: "\e95e";
  color: #6d66dc;
}
.icon-add-order-new:before {
  content: "\e95f";
  color: #fff;
}
.icon-partial-completion:before {
  content: "\e960";
}
.icon-route:before {
  content: "\e961";
  color: #6d66dc;
}
.icon-home-route:before {
  content: "\e962";
  color: #6d66dc;
}
.icon-monitoring:before {
  content: "\e963";
  color: #7f79e0;
}
.icon-paperclip:before {
  content: "\e964";
}
.icon-non-service:before {
  content: "\e965";
  color: #3127a5;
}
.icon-assignment-confirm:before {
  content: "\e966";
  color: #6d66dc;
}
.icon-menu-open:before {
  content: "\e967";
  color: #859099;
}
.icon-today:before {
  content: "\e968";
}
.icon-spin:before {
  content: "\e969";
  color: #fff;
}
.icon-unscheduled:before {
  content: "\e96d";
}
.icon-assigned-to-coordinator:before {
  content: "\e96e";
}
.icon-assigned:before {
  content: "\e96f";
}
.icon-scheduled:before {
  content: "\e970";
}
.icon-phone:before {
  content: "\e971";
}
.icon-error-solid:before {
  content: "\e900";
}
.icon-unclaimed:before {
  content: "\e901";
}
.icon-admin-chat:before {
  content: "\e902";
  color: #fff;
}
.icon-reset-all:before {
  content: "\e903";
}
.icon-filter-expand:before {
  content: "\e904";
}
.icon-global-search:before {
  content: "\e96a";
  color: #859099;
}
.icon-care-at-home-schedule-old:before {
  content: "\e96b";
  color: #859099;
}
.icon-timezone:before {
  content: "\e96c";
  color: #868686;
}
.icon-care-at-home-schedule:before {
  content: "\e972";
  color: #859099;
}