/*mixins for border */

@mixin input-border($width: 1px, $color: #fff) {
  border: $width solid $color;
}

/* Media queries mixins */

/* Breakpoint sizes */
$screen-max1024: 1024px;
$screen-max1600: 1600px;
$screen-min1366: 1366px;
$screen-min1920: 1920px;
$screen-1200: 1200px;

@mixin breakpoint($media) {
  @if $media==max1024 {
    @media only screen and (max-width: $screen-max1024) {
      @content;
    }
  }

  @else if $media==max1600 {
    @media only screen and (max-width: $screen-max1600) {
      @content;
    }
  }

  @else if $media==max1200 {
    @media only screen and (max-width: $screen-1200) {
      @content;
    }
  }

  @else if $media==min1366 {
    @media only screen and (min-width: $screen-min1366) {
      @content;
    }
  }

  @else if $media==maxmin1366 {
    @media only screen and (min-width: $screen-max1024) and (max-width: 1400px) {
      @content;
    }
  }

  @else if $media==max1366 {
    @media only screen and (max-width: 1365px) {
      @content;
    }
  }

  @else if $media==min1920 {
    @media only screen and (min-width: $screen-min1920) {
      @content;
    }
  }

  @else if $media==min1600 {
    @media only screen and (min-width: $screen-max1600) {
      @content;
    }
  }

  @else if $media==retina {
      @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
      only screen and (min--moz-device-pixel-ratio: 1.25),
      only screen and (-o-min-device-pixel-ratio: 1.25/1),
      only screen and (min-device-pixel-ratio: 1.25),
      only screen and (min-resolution: 200dpi),
      only screen and (min-resolution: 1.25dppx) {
        @content;
      }
  }
}
/* Icomoon mixins */
@mixin icomoon($icon, $fontS-size, $position: "before", $replace: false) {
  // If we're replacing the text, set font-size to 0
  @if $replace {
    font-size: 0;
  }
  // Pseudo-element properties
  &:#{$position} {
    @extend .icon-#{$icon};
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: $fontS-size;
    @content;
  }
}
//funtion to convert px to rem
@function pxToRem($pxValue) {
  @return ($pxValue / 16px) * 1rem;
}
