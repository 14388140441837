//common classes to go on common/main scss file
.border-chat {
    border: 1px solid $grey-color7;
}
.background-properties {
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}
.ad-main-container {
    .aha-chat-wrapper {
        display: flex;
        height: calc(100% - 60px);
        .left-chat-wrapper {
            flex: 0 0 276px;
            max-width: 276px;
            overflow: hidden;
            .ch-unread-badge {
                top: 23px;
                left: unset;
                right: -8px;
                color: transparent;
                background: transparent;
                &::before {
                    content: "";
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: $red-color;
                    z-index: 99;
                    border-radius: 50%;
                }
            }

            .channel-list-wrapper {
                overflow: hidden;
                &.chat-individual {
                    height: 60%;
                }
                &.chat-group {
                    height: 40%;
                }
            }
            .ch-channel-list-wrapper {
                width: auto;
                height: calc(100% - 44px);
                overflow-x: hidden;
                overflow-y: overlay;
                li {
                    cursor: pointer;
                    &.ch-unread .username-label {
                        font-family: $roboto-medium;
                        font-weight: normal;
                    }
                    &.ch-unread.ch-selected{
                        .ch-unread-badge{
                            right:8px;
                        }
                    }
                }
            }
            .ch-channel-button {
                height: 56px;
                background-color: transparent;
                border: none;
                padding: 15px 8px;
                &:hover {
                    background-color: $grey-color16;
                }
                &:focus {
                    border: transparent;
                }
                .user-name-wrapper {
                    display: flex;
                    align-items: center;
                    .username-label {
                        width: 100%;
                        max-width: 75%;
                        font-size: pxToRem(16px);
                        font-family: $roboto-regular;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        text-align: left;
                        text-transform: capitalize;
                        color: $label-color;
                    }
                }
            }
            .channel-list-header {
                height: 44px;
                border-color: $grey-color7;
                border-bottom: 1px solid $grey-color7;
            }
            .ch-label {
                width: 100%;
                padding-left: 0;
            }
            .channel-list-header-title {
                width: 100%;
                font-family: $roboto-medium;
                font-size: pxToRem(16px);
                letter-spacing: 0.1px;
                line-height: 24px;
                padding-left: 0;
            }
            .channel-list-header-title {
                color: $dark-blue-color;
            }
            .channel-list-wrapper.chat-group {
                border-top: 1px solid $grey-color7;
            }
            .ch-popover-toggle {
                top: 55%;
                transform: translateY(-50%);
                right: 9px;
                margin: 0;
                height: auto;
                @include icomoon("three-vertical-dots", 19px);
                svg {
                    display: none;
                }
                &::before{
                    color:$grey-color11;
                }
                &:hover,
                &:active {
                    background-color: transparent;
                }
                g {
                    fill: $dark-grey-color;
                }
            }

            .ch-popover-menu {
                background-color: $white-color;
                border-radius: 8px;
                li {
                    height: auto;
                    button {
                        padding: 10px 20px;
                        text-transform: capitalize;
                        &:hover,
                        &:focus {
                            background-color: $grey-color16;
                            color: $dark-grey-color;
                        }
                    }
                }
                .separator {
                    display: none;
                }
            }
            .create-channel-button {
                padding: 0;
                height: auto;
                text-align: right;
                .ch-icon {
                    height: auto;
                }
                &:hover {
                    background-color: transparent;
                }
                &:focus,
                &:active {
                    border: none;
                    box-shadow: none;
                }
                &:active{
                    background-color: transparent;
                }
            }
        }

        .right-chat-wrapper {
            flex: 1 0 calc(100% - 566px);
            display: flex;
            flex-direction: column;
           
            .user-name-wrapper {
                display: flex;
                align-items: flex-start;
                .username-label {
                    padding-left: 10px !important;
                    text-align: left;
                    text-transform: capitalize;
                    color: $label-color;
                }
            }

            div,
            a {
                
                font-size: pxToRem(14px);
                font-family: $roboto-regular;
                letter-spacing: 0.252px;
            }
            .lb-header {
                align-items: center;
                .lb-button {
                    padding: 18px 25px;
                }
            }
            a {
                color: $blue-color15;
                &:hover { cursor: pointer; }
            }

            .ch-msg-list-header {
                background-color: $white-color2;
                border-bottom: 1px solid $grey-color7;
                min-height: 44px;
                display: flex;
                align-items: center;
                padding: 0 15px;
            }
            .channel-name {
                line-height: 24px;
                color: $grey-color;
                text-transform: capitalize;
                letter-spacing: 0.12px;
            } 
            ul {
                height: calc(100% - 74px);
                padding: 20px 11px 0 15px;
                background-color: $white-color;
                text-align: center;
                overflow-y: auto;
                .chat_detail_unread{
                    display:none;
                }
                .chat_unread_parent .chat_detail_unread{
                    display:block;
                    position: relative;
                    padding-bottom: 25px;
                    font-size: 0.75rem;
                    font-family: $roboto-regular;
                    text-transform: uppercase;
                    letter-spacing: 0.396px;
                    color: $blue-color16;
                    &::before,
                    &::after{
                        content: '';
                        position: absolute;
                        top: 6px;
                        width: 44%;
                        height: 100%;
                        border-color: $blue-color16;
                        border-top: 1px solid;
                    }
                    &::before {
                        left: 0;
                        
                    }
                    &::after {
                        right: 0;
                    }
                }
                .ch-date-header {
                    padding: 0 0 25px 0;
                    color: $blue-color16;
                    background-color: transparent;
                    text-transform: uppercase;
                    font-size: pxToRem(12px);
                    font-family: $roboto-regular;
                    letter-spacing: 0.396px;
                    line-height: 16px;
                }
                .ch-bubble-container {
                    margin: 0 0 25px 0;
                    background-color: $white-color;
                    text-align: left;
                    .ch-img-preview {
                        a {
                            position: relative;
                            z-index: 5;
                            display: inline-block; 
                        }
                        @include breakpoint(min1366) {
                            width: 500px;
                        }
                        &:hover { cursor: pointer; }
                        .pdf-thumbnail {
                            width: 90px;
                            height: auto;
                        }
                    }
                }
                .ch-bubble-inner {
                    max-width: none;
                    padding: 0;
                    background-color: transparent;
                    box-shadow: none;
                    color: $dark-grey-color;
                    .ch-content-container {
                        margin-top: -12px;                        
                        font-size: pxToRem(14px);
                        line-height: 20px;
                        letter-spacing: 0.252px;  
                        img{
                            cursor:pointer;
                        }                      
                    }
                    .ch-img-preview,
                    .ch-content-container,
                    .ch-attachment-name { margin-left: 46px; }
                }
                .ch-header {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 0;
                }
                .ch-sender-name {
                    letter-spacing: 0.09px;
                    font-size: pxToRem(14px);
                    text-transform: capitalize;
                    font-family: "robotomedium", sans-serif;
                    font-weight: normal;
                }
                .ch-header-timestamp {
                    font-size: pxToRem(12px) !important;
                    color: $grey-color5;
                    letter-spacing: 0.39px !important;
                    padding-left: 10px !important;
                }
            }
            .message-input-container {             
                margin: 0 15px 15px;
                align-items: flex-end;
                border: none;
                .message-input-form {
                    position: relative;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    max-height: 180px;
                    overflow-y: auto;
                    padding: 0;
                    min-height: 40px;
                    margin:0;
                    background-color: #fff;
                    border-radius: 20px;
                    border: 1px solid $grey-color7;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, .10);
                    
                    button[type="button"]  {
                        display: flex;
                        justify-self: center;
                        align-items: center;
                        padding: 0;
                        right: 15px;    
                        @include icomoon('close',10px);
                        &::before{
                            color:$grey-color11;
                            // padding-right: 10px;
                        }
                        svg{display: none;}
                    }
                   
                    .attach-icon-wrapper {
                        position: absolute;
                        bottom: 7px;
                        right: 8px;
                        .attach { 
                            color: $light-blue-color;
                            border: none;
                            box-shadow: none;
                            &:disabled {
                                color: #DEDEDE;
                                &:hover {
                                    color: #DEDEDE;
                                }
                            }
                        }
                    }
                    .file-attachment-wrapper {
                        // position: absolute;
                        // left: 12px;
                        // top: 25px;
                        max-width: 95%;
                        display: flex;
                        flex-wrap: wrap;
                       .ch-icon { height: 12px; }
                       label {
                        font-size: 0.75rem;
                        color: #121212
                       }
                       .attach-container {
                        display: flex;
                        align-items: center;
                       }
                    }
                    &.inactive-text {
                        opacity: .5;
                    }
                }
                .ch-send-button {
                    margin: 0 0 5px 10px;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                   
                    .msgsend-icon::before {
                        font-size: 24px;
                    }
                    &.ch-active {
                        .msgsend-icon::before {
                            color: $light-blue-color;
                        }
                    }
                }
                .ch-msg-wrapper {
                    padding-top: 8px;
                    padding-bottom: 1px;
                }
                textarea {
                    padding: 0 30px 0 12px;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                    max-height: 100px;
                    resize: none;
                    line-height: 1.2;
                    &:focus-visible { outline: 0; }
                    &:disabled { background-color: transparent; }
                }
                .attach {
                    margin: 0;
                    padding: 8px 0 0 0;
                    width: auto;
                    &:hover {
                        background-color: transparent;
                        g {
                            color: $dark-grey-color;
                        }
                    }
                    &:disabled g {
                        color: #DEDEDE;
                        &:hover {
                            color: #DEDEDE;
                        }
                    }
                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                }
                
            }
            .lb-icon-download {
                padding: 9px 18px 6px !important;
                &::before {
                    font-size: 18px;
                    color: #fff;
    
                }
            }
            .lb-icon-print {
                min-width: auto;
                height: 35px;
                background-color: #777;
                &::after {
                    content: "";
                    height: 18px;
                    width: 18px;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-left: 10px;
                    background-image: url("../images/print-solid.svg");
                }
            }
        }
        .channel-members-container {
            flex: 0 0 290px;
            max-width: 290px;
            background-color: $white-color;
            @include breakpoint(max1024) {
                flex: 0 0 220px;
                max-width: 220px;
            }
            .channel-members-list-header {
                padding: 0 15px;
                min-height: 44px;
                display: flex;
                align-items: center;
                margin: 0;
                background-color: #fff;
            }
            .channel-list-header-title {
                padding: 0;
            }
        }
        .ch-placeholder {
            color: $blue-color11;
            font-size: pxToRem(20px);
        }
        .channel-members-list-item {
            width: 100%;
            padding: 15px;
            .ch-channel-button {
                padding: 0 0 15px 0;
                text-align: left;
                color: $dark-grey-color;
                border: none;
                &:hover {
                    background-color: transparent;
                    cursor: default;
                }
                &:focus {
                    border: none;
                }
                &:active {
                    color: $dark-grey-color;
                    background-color: transparent;
                }
            }
            .ch-label {
                padding: 0;
                text-transform: capitalize;
            }
        }
        .ch-selected .ch-channel-button {
            background-color: $blue-color5;
            color: $label-color;
            font-family: "robotomedium", sans-serif;
        }
    }
}
.ch-main-modal {
    section {
        background: $white-color;
        border-radius: 8px;
        min-height: 468px;
        width: 525px;
    }
    header,
    .modal-body {
        padding: 15px 15px 0;
        border: none;
        .ch-title {
            font-size: pxToRem(24px);
            line-height: 36px;
            color: $label-color;
        }
        .leave-chat {
            max-width: 300px;
            margin: 15px auto;
            color: #191c1d;
            font-style: italic;
            font-size: pxToRem(12px);
            letter-spacing: 0.39px;
            text-align: center;
            line-height: 16px;
        }
    }
    footer {
        padding: 15px;
        border: none;
        button {
            margin-left: 15px !important;
            &.primary-btn-outline:focus{
                box-shadow: 0 0 0 0.25rem $blue-color17;
            }
            &:disabled,
            &:disabled:focus,
            &:disabled:hover{
                border: none; 
                box-shadow: none;
                color: $label-color;
                background: $light-grey-color;
            }
        }
    }
    .ch-form-wrapper {
        div > div {
            margin-top: 0;
            margin-bottom: 0;
            text-transform: capitalize;
        }
        .css-4g9mkt-menu,
        .css-26l3qy-menu {
            div > div,
            .css-1n7v3ny-option {
                font-size: pxToRem(14px);
                color: $dark-grey-color;
                letter-spacing: 0.25px;
                text-transform: capitalize;
                background-color: transparent;
                &:hover {
                    cursor: pointer;
                    background-color: $grey-color14;
                }
            }
            .css-gg45go-NoOptionsMessage:hover {
                cursor: default;
                background-color: transparent;
            }
        }
        .css-xb97g8 {
            padding-right: 8px;
            color: $grey-color11;
            &:hover {
                background-color: transparent;
            }
        }
        .modal-select-input__indicators,
        .modal-select-input__indicator,
        .modal-select-input__loading-indicator {
            position: absolute;
            top: 0;
            right: 0;
            color: $grey-color11;
            padding: 0;
            &:hover {
                cursor: pointer;
            }
            &:first-child {
                right: 22px;
            }
        }
        .modal-select-input__menu-list {
            max-height: 169px;
            overflow-y: auto;
        }
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        ::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background-clip: content-box;
        }
        ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $grey-color6;
        border-right: 4px solid transparent;
        }
        .modal-select-input__indicators {
            @include icomoon("arrow-down", 6px);
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            svg {
                display: none;
            }
        }
        .css-1gtu0rj-indicatorContainer:first-child {
            position: absolute;
            right: 22px;
        }
        .css-1gtu0rj-indicatorContainer {
            padding: 0;
        }
        .modal-select-input__single-value {
            color: $label-color;
            letter-spacing: 0.23px;
            font-family: "robotoregular", sans-serif;
            text-transform: capitalize;
        }
    }
    label {
        font-size: 0.75rem;
        line-height: 32px;
        font-family: "robotomedium", sans-serif;
        color: $label-color;
    }
    .modal-input input,
    .modal-select-input__control{
        margin-top: 0;
        width: 100%;
        min-height: 40px;
        padding: 5px 10px 0;
        border-radius: 5px;
        border: 1px solid $input-border-color;
        font-family: $roboto-light;
        font-size: pxToRem(16px);
        color: $dark-grey-color !important;
        &:disabled,
        &.disabled {
            background-color: $disabled-color;
        }
        .modal-select-input__value-container {
            padding: 0;
        }
    }
    #edit-channel {
        span:first-child {
            position: relative;
            display: inline-block;
            width: 100%;
        }
        button {
            top: 15px;
            right: 15px;
            width: 10px;
            height: 10px;
            transform: none;
            background-image: url("../images/close.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
            svg {
                display: none;
            }
        }
    }
    .modal-input input {
        padding: 5px 35px 5px 10px;
    }
    .modal-select-input__indicator-separator {
        display: none;
    }
    .ch-view-mem-wrapper {
        li {
            margin-bottom: 10px;
        }
        p {
            letter-spacing: 0.39px;
        }
        .name {
            color: rgba(0, 0, 0, 0.87);
            text-transform: capitalize;
            letter-spacing: 0.14px;
            line-height: 22px;
            font-size: 1rem;
        }
        .role {
            letter-spacing: 0.39px;
            font-size: 0.75rem;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.87);
        }
    }
    &.view-members {
        .ch-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 50px;
        }
    }
    .css-at12u2-loadingIndicator {
        position: absolute;
        right: 12px;
    }
}
.attachment-preview,
.ch-main-modal span.ch-close-button {
    padding: 0;
    right: 15px;
    top: 12px;
    .ch-icon {
        background-image: url("../images/close.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        svg {
            display: none;
        }
    }
    button:hover {
        background-color: transparent;
    }
    button:focus {
        border: none;
        box-shadow: none;
    }
}
.attachment-preview {
    button {
        padding-top: 4px;
    }
    .ch-icon {
        background-size: 10px;
    }
}
div[data-testid="notification-group"] {
    position: absolute;
    top: 0;
    div {
        background-color: transparent;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16%);
        border: none;
        color: $dark-blue-color;
    }
    .ch-message {
        font-size: pxToRem(14px);
        font-family: $roboto-regular;
    }
    .ch-severity-icon {
        svg{display: none;}
        
        box-shadow: none;
    }
    button {
        background-color: transparent!important;
        color: $dark-blue-color;
        border: none;
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
            box-shadow: none;
        }
    }
    .ch-icon {
        background-image: url("../images/close.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        svg {
            display: none;
        }
    }
}
.multi-user-icon {
    width: 30px;
    height: 30px;
    background-image: url("../images/group_chat1.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.chat-page {
    .sb-avatar.sb-avatar--text {
        span {
            font-size: pxToRem(14px);
            font-family: $roboto-medium;
            font-weight: normal;
            letter-spacing: 0.56px;
            color: $white-color;
        }
    }
    .ch-main-modal .sb-avatar.sb-avatar--text span {
        font-size: pxToRem(10px);
    }
    .name-chip-wrapper {
        display: flex;
        align-items: center;
        .name-chip-label {
            font-size: pxToRem(13px);
            font-family: $roboto-regular;
            color: $label-color;
            padding-left: 10px;
        }
    }
    .chat-group-icon,
    .chat-room-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
    }
    .chat-group-icon {
        border-radius: 50%;
        @include icomoon("group-chat", 20px);
    }
    .chat-room-icon {
        font-size: pxToRem(14px);
        font-family: $roboto-bold;
        border-radius: 4px;
        text-transform: uppercase;
    }
    .left-chat-wrapper {
        ::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }
 
    .css-g1d714-ValueContainer {
        padding: 0;
    }
    .modal-select-input__control{
        max-height: 162px;
        overflow-y: auto;
        padding: 0 5px;
        border-radius: 6px;
        .css-1iu73oo{
                padding-left: 3px;
        }
        div[role="button"] {
            background-image: url("../images/close.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px;
            margin: 10px;
            svg{display: none;}
        }
        
    }
    
    .modal-select-input__control:focus-within ,
    .modal-select-input__control:focus{
        box-shadow: none!important;
        border: 2px solid $blue-color3;
        border-radius: 6px;
    }
    .css-yt9ioa-option:hover {
        background-color: $grey-color16;
    }
    header {
        min-height: auto;
    }
    .ch-form-field-input input:focus{
        box-shadow: none!important;
        border: 2px solid $blue-color3;
        border-radius: 6px;
    }
    &.admin-chat-page {
        .sch-statlisting-select__control {
            ::-webkit-scrollbar-thumb {
                background: $grey-color6 !important;
            }
        }
        input.form-control {
            height: 28px;
            border-radius: 0;
            font-size: 12px !important;
            padding: 0 15px;
            color: #000 !important;
            &.keyword-search {
                min-width: 200px;
                padding-right: 50px;
                &.search-box {
                    padding-right: 30px;
                }
            }
        }
    }
    mark,
    .mark {
        padding: 0;
    }
}
// Filter on Mapbox
.ch-filter-wrapper {
    position: absolute;
    top: 48px;
    right: 55px;
    min-width: 110px;
    z-index: 1; 
    input[type='checkbox'] {
        display: none;
    }
    label {
        display: block;
        min-height: 32px;
        padding: 8px 10px; 
        line-height: normal; 
        font-family: $roboto-regular; 
        cursor: pointer;
        text-transform: capitalize;
        background-color: $blue-color1;
        color: $grey-color7 ;
    }
    input[type='checkbox']+label:hover {
        background-color: rgba(11, 32, 50,.92);
        color: $white-color;
    }
    input[type='checkbox']:checked+label:before {
        content: '✔';
        margin-right: 5px; 
    }
    input[type='checkbox']:checked+label,
    input[type='checkbox']:checked+label:before { color: $white-color; }
}

//Admin chat page styles starts here
.admin-chat-page .ad-main-container .aha-chat-wrapper {
    height: calc(100% - 146px);
}
.stat-order-wrapper.ac-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .ac-top-select-people-wrapper {
        flex: 1;
        max-height: 86px;
        height: 86px;
        display: flex;
        align-items: flex-start;
    }
    .ac-chat-type-select {
        width: 204px;
        height: auto;
        margin: 0 0 6px 15px;
        padding: 0;
        .sch-statlisting-select__control { min-height: 40px;}
        .sch-statlisting-select__value-container--is-multi input { position: absolute !important;}
    }
    .sch-statlisting-select__control .sch-statlisting-select__value-container--is-multi input {
        position: static;
    }
    .ac-select-contact { 
        flex: 1;
        height: 100%;
        padding: 0;
        background-color: #F8F9FA;
        .sch-statlisting-select__menu {
            top: 0;
        }
        .sch-statlisting-select__menu-list {
            max-height: 330px;
            overflow-y: auto;
        }
        .sch-statlisting-select__control {
            height: 100%;
            max-height: 86px;
            overflow-y: auto;
            align-items: flex-start;
            ::-webkit-scrollbar-thumb {
                background: $grey-color6 !important;
            }
            .sch-statlisting-select__value-container { padding: 0;}
            & ::-webkit-scrollbar-thumb { background: $grey-color6; }
            .sch-statlisting-select__indicators { height: 82px; }
        }
        .sch-statlisting-select__multi-value {
            height: 28px;
            .sb-avatar,
            .sb-avatar__text {
                width: 20px !important;
                height: 20px !important;
            }
            .sb-avatar.sb-avatar--text span {
                font-size: 10px;
            }
            .name-chip-wrapper .name-chip-label {
                font-size: 13px;
            }
            .sch-statlisting-select__multi-value__remove {
                margin: 0 6px;
            }
        }
        .sch-statlisting-select__loading-indicator,
        .sch-statlisting-select__loading-indicator::before  {
            display: none;
        }
      
    }
    .primary-btn {
        min-width: 204px;
        margin: 0 0 0 15px;
    }
    &.ch-add-member {
        .ac-select-contact .sch-statlisting-select__control {
            max-height: 250px;
            align-items: center;
            .sch-statlisting-select__indicators { height: auto; max-height: 250px; }
        }
    }
}
.ac-chat-list-header {
    justify-content: space-between;
    @include breakpoint(max1200) {
        flex-wrap: wrap;
    }
    > div {
        margin: 6px 0;
    }
    .stat-input-group  {
        width: auto;
        height: 28px;
        @include icomoon("Schedule", 14px);
        &:before {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
        }
        .daterange-form-input {
            min-width: 200px;
            padding: 0 15px 0 26px !important;
        }
    }
  
    .add-dropoff-title {
        background: transparent;
        border: none;
        padding: 0 12px;
        &.with-border-right {
            border-right: 2px solid;
            align-self: center;
        }
    }
    .ac-ksearch-wrapper {
        position: relative;
        .ac-no-of-chat {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #767676;
        }
        &.ug-search-wrapper {
            .search-box {
                padding-right: 50px;
            }
        }
    }
}

.orange-highlight {
    background-color: #f39539;
}
.highlight-value {
    background-color: #f39539;
}
.ad-ch-input-wrapper {
    width: 100%;
    &.ch-input-search-new {
        .search-icon { 
            top: 4px; 
            &::before { font-size: 14px; }
        }   
        .ch-keyword-close { top: 4px; }    
        input.form-control.keyword-search { padding: 0 30px 0 25px; }
    }
    .search-icon {
        left: 0;
        right: unset;
        padding: 2px 5px;
        border: none;
        &::before {
            font-size: 12px;
        }
    }
    input.form-control.keyword-search {
        padding-left: 22px;
        min-width: auto;
        width: 100%;
        border-radius: 0;
    }
    .ch-keyword-close {
        position: absolute;
        right: 10px;
        top: 2px;
        padding: 1px 0;
        &:hover { cursor: pointer; }
        &::before {
            font-size: pxToRem(12px);
            color: $grey-color11;
        }
    }
}
